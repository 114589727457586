import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.TourStoppsAtg,
  label: 'Tour-ATG-Stopps',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: 'down',
  category: 'tours',
  definition: getDefinitionByName('Tour-Stopps-ATG'),
  nullValue: '-',
});

export default new SingleValueExpression(metric);
