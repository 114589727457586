import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoAtgZeit from './netto-atg-zeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  nettoAtgZeit,
  touren,
  'Ø Netto-ATG-Zeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Netto-ATG-Zeit')
);

expression.nullValue = '-';
expression.setIdentifier('netto-atg-zeit/touren');

export default expression;
