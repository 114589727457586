import DifferenceValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/DifferenceValueExpression';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import schadenVerlustRegresse from './schaden-verlust-regresse';
import schadenVerlustGutschriften from './schaden-verlust-gutschriften';
import abwicklungsmengeZsb from './abwicklungsmenge-zsb';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  new DifferenceValueExpression(schadenVerlustRegresse, schadenVerlustGutschriften),
  abwicklungsmengeZsb,
  'S&V-Quote (ZSB/VP+)',
  true,
  '-#.###.##0,##0%',
  undefined,
  getDefinitionByName('S&V-Quote (ZSB/VP+)')
);

expression.decimalPlaces = 3;
expression.nullValue = '-';
expression.setIdentifier('regression-gutschriften/abwicklungsmenge');

export default expression;
