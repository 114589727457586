/* eslint-disable max-len */
import MetricType, { ChartRenderOptions } from '../../../metrics2/models/entities/MetricType';
import { ValueExpressionRoutingParams } from '../routing/ValueExpressionRoutingParams';
import { CloneInterface } from '../../../utils/CloneInterface';
import MetricsEntityKey from '../../../metrics2/models/entities/MetricsEntityKey';
import ValueExpressionEntityKey from '../entities/ValueExpressionEntityKey';
import { ValueFormatter } from '../types/ValueFormatter';
import formatNumber from 'format-number-with-string';
import { PositiveDirections } from '../../../metrics2/models/entities/PositiveDirection';
import { ChartType } from '../../../metrics2/models/enumerations/ChartType';
import { ValueType } from '../../../metrics2/models/enumerations/ValueType';
import { AggregationType } from '../../../metrics2/models/enumerations/AggregationType';
import { KpiDefinitionWithoutName } from '@custom-types/kpi';

const stundenToMillisekundenMultiplier = 60 * 60 * 1000;

export default abstract class ValueExpression implements CloneInterface {
  abstract tag: string;
  identifier: string;
  public shortLabel?: string;
  public longLabel?: string;
  type: string;
  public chartRenderOptions: ChartRenderOptions = {};
  private _nullValue?: string;
  public get nullValue(): string {
    return this._nullValue;
  }
  public set nullValue(value: string) {
    this._nullValue = value;
  }
  public definition: KpiDefinitionWithoutName;

  abstract get key(): string;

  abstract get valueType(): ValueType;

  abstract get mapKeys(): Array<string> | null | undefined;

  abstract getRequiredMetricTypes(id?: string): Array<{ type: MetricType; valueKey?: string; id: string }>;

  abstract get chartType(): ChartType;

  get category(): string | null | undefined {
    return null;
  }

  get chartConfig(): Object {
    return {};
  }

  abstract get positiveDirection(): PositiveDirections;

  abstract get aggregation(): AggregationType;

  setIdentifier(value: string): void {
    this.identifier = value;
  }

  getShortLabel(mapKey: string | null | undefined = null, language: string | null | undefined = null): string {
    if (this.shortLabel) {
      return this.shortLabel;
    }

    return this.getLabel(mapKey, language);
  }

  abstract getLabel(mapKey?: string, language?: string): string;

  getDescription(
    mapKey: string | null | undefined = null,
    language: string | null | undefined = null
  ): string | null | undefined {
    return null;
  }

  getSumLabel(language: string | null | undefined = null): string {
    return this.getLabel(null, language);
  }

  getValueFormatter(language?: string): ValueFormatter {
    return (value: number) => {
      const safeValue = value || 0;
      let valueFormat = this.getValueFormat();
      if (valueFormat?.endsWith('hh:mm')) {
        const time = Intl.DateTimeFormat('de-DE', {
          timeStyle: 'short',
          // use UTC here because we doesn't want to deal with timezone offsets
          // if you set German timezone, you will get 23:00 instead of 22:00 after formatting
          timeZone: 'UTC',
        }).format(Math.abs(safeValue * stundenToMillisekundenMultiplier));
        return safeValue < 0 ? '-' + time : time;
      }
      if (!valueFormat.startsWith('-')) {
        valueFormat = `-${valueFormat}`;
      }
      if (valueFormat.endsWith('h')) {
        if (Math.abs(safeValue) < 1) {
          const minutes = Math.round(safeValue * 60);
          if (Math.abs(minutes) === 60) {
            return `${minutes / 60}:00 h`;
          }
          return `${minutes} min`; // 0,5 -> "30 min" 0,49 -> "29 min" 0.51 -> "31 min"
        }
        const rest = Math.abs(safeValue) % 1;
        const hours = Math.abs(safeValue) - rest;
        const roundedMinutes = Math.round(rest * 60);
        // Im Grenzfall wollen wir bei z.B. 59 min und 31 sec auf 1 Stunde runden anstatt auf 60 Minuten
        if (roundedMinutes === 60) {
          return `${hours + 1}:00 h`;
        }
        return `${safeValue >= 0 ? hours : -hours}:${roundedMinutes > 9 ? roundedMinutes : '0' + roundedMinutes} h`; // 7,5 -> "7:30 h" 7,49 -> "7:29 h" 7.51 -> "7:31 h"
      }
      return formatNumber(safeValue, valueFormat);
    };
  }

  abstract getValueFormat(): string;

  abstract processValues(
    metrics: Map<MetricsEntityKey, number>,
    forceNoneGrouping: boolean
  ): Map<ValueExpressionEntityKey, number>;

  getRoutingParams(): ValueExpressionRoutingParams {
    return { valueExpressionClass: '' };
  }

  abstract clone(): ValueExpression;

  getChildren(): Array<ValueExpression> {
    return [];
  }

  apiVersion: 'ws' | 'graphql' = 'ws';
}
