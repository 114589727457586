import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import nettoPSZeit from './netto-ps-zeit';
import psMitnahmen from './ps-mitnahmen';
import psAbgaben from './ps-abgaben';

const expression = new QuotientValueExpression(
  new SumValueExpression(psMitnahmen, psAbgaben),
  nettoPSZeit,
  'PS-Produktivität',
  false,
  undefined,
  undefined,
  getDefinitionByName('PS-Produktivität')
);

expression.nullValue = '-';
expression.setIdentifier('ps-produktivitaet');

export default expression;
