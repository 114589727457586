import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoATGZeit from './netto-atg-zeit';
import atgAbholungen from './atg-abholungen';

const expression = new QuotientValueExpression(
  atgAbholungen,
  nettoATGZeit,
  'ATG-Produktivität',
  false,
  undefined,
  undefined,
  getDefinitionByName('ATG-Produktivität')
);

expression.nullValue = '-';
expression.setIdentifier('atg-produktivitaet');

export default expression;
