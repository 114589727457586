import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const metric = new MetricType({
  key: MetricTypeKey.NettoPSZeit,
  label: 'Netto-PS-Zeit',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0,0 h',
  category: 'tours',
  definition: getDefinitionByName('Netto-PS-Zeit'),
  hidden: true,
  nullValue: '-',
});

const expression = new QuotientValueExpression(
  new SingleValueExpression(metric),
  new StaticValueExpression(3600),
  'Netto-PS-Zeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Netto-PS-Zeit')
);

expression.nullValue = '-';
expression.setIdentifier('netto-ps-zeit');

export default expression;
