import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import psAbgaben from './ps-abgaben';
import touren from './touren';

const expression = new QuotientValueExpression(
  psAbgaben,
  touren,
  'Ø PS Abgaben',
  false,
  undefined,
  undefined,
  getDefinitionByName('PS Abgaben')
);

expression.nullValue = '-';
expression.setIdentifier('psabgaben/touren');

export default expression;
