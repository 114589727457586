import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import touren from './touren';
import istLademenge from './lademenge-ist';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  istLademenge,
  touren,
  'ø IST-Lademenge',
  null,
  null,
  null,
  getDefinitionByName('ø IST-Lademenge')
);

expression.setIdentifier('ist_lademenge/touren');
expression.nullValue = '-';

export default expression;
