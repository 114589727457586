import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.FinishedDeliveriesStopJourneyLocationTimeLag,
  label: 'GPS Verzögerung',
  aggregation: AggregationType.sum,
  chart: ChartType.line,
  positiveDirection: 'down',
  category: 'tours',
  description: '<p>Zeitliche Abweichung der Koordinate zum Stop in Sekunden</p>\n',
  nullValue: '-',
});

export default new SingleValueExpression(metric);
