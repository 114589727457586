import React, { ReactElement, useMemo, useState } from 'react';
import OrgUnitNode from '../../../../modules/metrics2/models/websocket/org/OrgUnitNode';
import { TourIdentifier } from '../../../../modules/tour/models/state/tourDetails/TourIdentifier';
import FinishedDelivery from '../../../../modules/tour/models/entities/FinishedDelivery';
import TourDetails from '../../../../modules/tour/models/entities/TourDetails';
import { parse } from 'svg-parser';
import { Svg, G, Path, pdf } from '@react-pdf/renderer';
import { Button, Spinner } from 'reactstrap';
import styles from './touren-report.module.scss';
import { FaDownload } from 'react-icons/fa';
import { useOrgTreeContext } from '@contexts/org-tree-context';

type WithTourenReportActionProps = {
  tourIdentifier: TourIdentifier;
  finishedDeliveries: FinishedDelivery[];
  tourDetails: TourDetails;
  imageUrls: Record<number, string>;
};
export type TourenReportActionProps = Omit<WithTourenReportActionProps, 'imageUrls'> & {
  orgUnit: OrgUnitNode;
  loading: boolean;
  signatureMap: Map<string, ReactElement>;
};
export const withTourenReportAction =
  (WrappedComponent: React.ComponentType<TourenReportActionProps>) =>
  ({ imageUrls, finishedDeliveries, tourIdentifier, ...props }: WithTourenReportActionProps) => {
    const [signaturesMap, setSignaturesMap] = useState(new Map<string, ReactElement>([]));
    const [loading, setLoading] = useState(false);

    const orgTreeCtx = useOrgTreeContext();

    const orgUnit = useMemo(
      () => findNodeInTree(orgTreeCtx?.orgTree?.rootNode, `oz:${tourIdentifier.orgId}`),
      [orgTreeCtx?.orgTree?.rootNode, tourIdentifier.orgId]
    );

    const loadReport = async () => {
      setLoading(true);
      !!imageUrls &&
        Promise.all(
          Object.entries(imageUrls)
            .filter(([tourNr, url]) => {
              return finishedDeliveries.some((fd) => fd?.displayableStopNumber?.toString() === tourNr);
            })
            .map(([tourNr, url]) => {
              return fetch(url).then((response) =>
                response.blob().then((blob) =>
                  blob.text().then((text) => {
                    const parsedSvg: RootNode = parse(text);
                    const svg = constrainSvg(parsedSvg, tourNr);
                    setSignaturesMap((signatures) => {
                      signatures.set(tourNr, svg);
                      return signatures;
                    });
                  })
                )
              );
            })
        )
          .then(() => {
            const _pdf = pdf();
            _pdf.updateContainer(
              <WrappedComponent
                {...props}
                finishedDeliveries={finishedDeliveries}
                signatureMap={signaturesMap}
                orgUnit={orgUnit}
                tourIdentifier={tourIdentifier}
                loading={loading}
              />
            );
            return _pdf.toBlob().then((blob) => {
              // Create blob link to download
              const url = URL.createObjectURL(blob);

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `touren-report-${tourIdentifier.orgId}-${tourIdentifier.number}-${tourIdentifier.date}.pdf`
              );

              const clickHandler = () => {
                setTimeout(() => {
                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                  URL.revokeObjectURL(url);
                  setLoading(false);
                }, 150);
              };

              link.addEventListener('click', clickHandler, false);

              // Append to html link element page
              document.body.appendChild(link);

              // Start download
              link.click();
            });
          })
          .catch(() => {
            setLoading(false);
          });
    };

    return (
      <Button onClick={loadReport} disabled={loading} color='primary' className={styles.tourenReportAction}>
        {loading ? <Spinner color='light' size='sm' /> : <FaDownload />}
      </Button>
    );
  };

type ElementNode = {
  type: 'element';
  tagName?: string;
  properties?: Record<string, string | number>;
  children: Array<ElementNode | string>;
  value?: string;
  metadata?: string;
};
export interface RootNode {
  type: 'root';
  children: [ElementNode];
}

const constrainSvgChild = (child: ElementNode, key: string): ReactElement => {
  switch (child.tagName) {
    case 'path':
      return (
        <Path
          key={`${key}-path`}
          d={child.properties['d'] as any}
          strokeWidth={child.properties['stroke-width'] as any}
        />
      );
    case 'g':
      return (
        <G
          key={`${key}-g`}
          fill={child.properties['fill'] as any}
          stroke={child.properties['stroke'] as any}
          strokeLineCap={child.properties['stroke-linecap'] as any}>
          {child.children.map((child, index) => constrainSvgChild(child as ElementNode, `${key}-${index}`))}
        </G>
      );
    default:
      return <></>;
  }
};
const constrainSvg = (svg: RootNode, key: string): ReactElement => {
  const { width, height } = svg.children[0].properties;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <Svg width={'100%'} height={'auto'} viewBox={viewBox}>
      {svg.children[0].children.map((child, index) => constrainSvgChild(child as ElementNode, `${key}-${index}`))}
    </Svg>
  );
};
const findNodeInTree = (node: OrgUnitNode, orgKey: string): OrgUnitNode => {
  const comparisonFunction = (val) => val?.orgUnit?.['orgKey'] === orgKey;
  if (comparisonFunction(node)) {
    return node;
  }
  if (!node) return null;
  if (!node.children || !node.children.length) {
    return null;
  }
  for (let i = 0; i < node.children.length; i++) {
    const match = findNodeInTree(node.children[i], orgKey);
    if (match) {
      return match;
    }
  }
  return null;
};
