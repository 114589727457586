import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import stoppsanzahlNachweisart from './stoppsanzahl-nachweisart';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const expression = new QuotientValueExpression(
  new MapDetailValueExpression(stoppsanzahlNachweisart.metricType, 'imageSignature'),
  new SumValueExpression(
    new MapDetailValueExpression(stoppsanzahlNachweisart.metricType, 'signature'),
    new MapDetailValueExpression(stoppsanzahlNachweisart.metricType, 'imageSignature')
  ),
  'Nutzungsquote Foto-Unterschriften',
  true,
  undefined,
  undefined,
  getDefinitionByName('Nutzungsquote Foto-Unterschriften')
);

expression.nullValue = '-';
expression.setIdentifier('imageSignature_quote');

export default expression;
