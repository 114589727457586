/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Row, Button, InputGroup } from 'reactstrap';
import { MdCompareArrows, MdHighlightOff } from 'react-icons/md';
import { get } from 'lodash';
import classNames from 'classnames';
import { ChartOptions, ChartTooltipLabelColor, ChartYAxe } from 'chart.js';

import ChartUtils, { chartCustomFnFactory, chartHoverFnFactory } from '../utils/ChartUtils';
import MetricsDataProvider from '../../metrics2/services/MetricsDataProvider';
import DetailedChartLegend from '../charts/DetailedChartLegend';
import { Duration } from '../models/enums/Duration';
import DetailedChartKpiValue from '../charts/DetailedChartKpiValue';
import GroupedDataConnection from '../../dataconnection/connections/GroupedDataConnection';
import LoadingChart from '../../overview/assets/loading_chart.svg';
import { defaultDuration } from '../models/enums/ComponentOptions';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import KpiSelector from './KpiSelector';
import { ValueType } from '../../metrics2/models/enumerations/ValueType';
import QuotientValueExpression from '../../valueexpressions/models/valueexpressions/QuotientValueExpression';
import DetailedChartTooltip from '../charts/DetailedChartTooltip';
import DateSelect from '../../common/components/DateSelect';
import { formatValueExpressionValue } from '../utils/FormatValueExpression';
import { Position } from '../types/Position';
import { dashboardSlice, selectDashboardOrgKey, selectLocalComponentOptions } from '@redux';
import { EmptyKpiSelectionInfo } from './EmptyKpiSelectionInfo';
import valueExpressionMap from '@contexts/value-expression-context/value-expressions';
import type { TooltipModel } from '../charts/DetailedChartTooltip';
import { useDataConnectionContext } from '@contexts/data-connection-context';
import { useValueExpressionDataContext } from '@contexts/value-expression-data-context';
import { useGroupedDataConnection } from '../hooks/useGroupedDataConnection';

import * as Loader from '../cssModules/loaderStyles.module.scss';
import Styles from './KpiCompareChart.module.scss';
import { useChartKpiQuery } from '@hooks/use-chart-kpi-query-hook';

type Props = {
  uID: string | null | undefined;
  showDateSelector: boolean;
  style?: React.CSSProperties;
};

const PRIMARY_AXIS_ID = 'A';
const COMPARE_AXIS_ID = 'B';

const KpiCompareChart: React.FC<Props> = (props: Props) => {
  const { showDateSelector, style, uID } = props;
  const valueExpressionDataContext = useValueExpressionDataContext();
  const dataConnectionContext = useDataConnectionContext();

  const dispatch = useDispatch();
  const orgKey = useSelector(selectDashboardOrgKey);
  const options = useSelector(selectLocalComponentOptions(uID, 'kennzahlenVergleich'));
  const tableOptions = useSelector(selectLocalComponentOptions(uID, 'kennzahlenTable'));

  const primaryValueExpressionKey = options?.primaryValueExpressionKey;
  const compareValueExpressionKey = options?.compareValueExpressionKey;
  const primaryValueExpression = valueExpressionMap.get(primaryValueExpressionKey);
  const compareValueExpression = valueExpressionMap.get(compareValueExpressionKey);
  const duration = options?.duration || defaultDuration(uID);

  const isPrimaryGqlExpression = primaryValueExpression?.apiVersion === 'graphql';
  const isComparisonGqlExpression = compareValueExpression?.apiVersion === 'graphql';

  const [tooltipModel, setTooltipModel] = useState<TooltipModel>({} as TooltipModel);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number>(-1);
  const [tooltipPosition, setTooltipPosition] = useState<Position>({ x: 0, y: 0 });

  const chartRef = useRef<Bar>(null);

  const setComponentsOptions = (componentId: string, optionKey: string, value: any) => {
    dispatch(
      dashboardSlice.actions.setLocalComponentOptions({
        componentId,
        optionKey,
        value,
      })
    );
  };

  const wsDataConnections = useMemo(() => {
    const dateRange = ChartUtils.getDateRange(duration);

    let primary = null;
    let compare = null;

    if (primaryValueExpression?.apiVersion === 'ws') {
      primary = ChartUtils.buildConnection(
        orgKey,
        dateRange.from,
        dateRange.to,
        primaryValueExpression,
        valueExpressionDataContext,
        duration,
        false
      );
    }
    if (compareValueExpression?.apiVersion === 'ws') {
      compare = ChartUtils.buildConnection(
        orgKey,
        dateRange.from,
        dateRange.to,
        compareValueExpression,
        valueExpressionDataContext,
        duration,
        false
      );
    }
    return new GroupedDataConnection({ ...(primary && { primary }), ...(compare && { compare }) });
  }, [compareValueExpression, duration, orgKey, primaryValueExpression, valueExpressionDataContext]);

  const { data: wsData, loading: wsLoading } = useGroupedDataConnection(
    wsDataConnections,
    dataConnectionContext,
    !(isPrimaryGqlExpression && isComparisonGqlExpression)
  );

  const { data: gqlPrimaryValueExpressionData, loading: gqlPrimaryLoading } = useChartKpiQuery({
    orgKey,
    duration,
    valueExpression: primaryValueExpression,
  });

  const { data: gqlComparisonValueExpressionData, loading: gqlComparisonLoading } = useChartKpiQuery({
    orgKey,
    duration,
    valueExpression: compareValueExpression,
  });

  const isLoading = wsLoading || gqlPrimaryLoading || gqlComparisonLoading;

  const _getChartOptions = (onActiveTooltipIndex: (idx: number) => void): ChartOptions => {
    const yAxes: ChartYAxe[] = [
      {
        id: PRIMARY_AXIS_ID,
        type: 'linear',
        position: 'left',
        stacked: false,
        gridLines: {
          color: 'rgba(0,0,0,0.04)',
        },
        ticks: {
          fontColor: 'rgba(0, 145, 205, 1)',
          beginAtZero: [primaryValueExpression, compareValueExpression]
            .filter((x) => x != null)
            .some((x) => x.chartRenderOptions?.startYAxisOnZero !== false),
          suggestedMax: (primaryValueExpression as QuotientValueExpression).percent ? null : 10,
          // step does exist
          // @ts-ignore
          step: 1,
          callback: (value) => formatValueExpressionValue(primaryValueExpression, value),
        },
      },
    ];

    if (compareValueExpressionKey && compareValueExpression) {
      yAxes.push({
        id: COMPARE_AXIS_ID,
        type: 'linear',
        position: 'right',
        stacked: false,
        // gridLines is wrong typed
        // @ts-ignore
        gridLines: false,
        ticks: {
          suggestedMax: (compareValueExpression as QuotientValueExpression).percent ? null : 10,
          beginAtZero: true,
          callback: (value) => formatValueExpressionValue(compareValueExpression, value),
        },
      });
    }

    return {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 20,
          bottom: 20,
        },
      },
      hover: {
        mode: 'nearest',
      },
      animation: { duration: 0 },
      maintainAspectRatio: false,
      scales: {
        yAxes,
        xAxes: [
          {
            stacked: false,
            gridLines: {
              color: 'rgba(0,0,0,0.04)',
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 10,
              maxRotation: 0,
              fontColor: 'hsl(203, 100%, 7%)',
            },
          },
        ],
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        position: 'nearest',
        enabled: false,
        callbacks: {
          title: (tooltipItem, data: any) => {
            const index = tooltipItem[0].index;
            return data.dataLabels[index];
          },
          beforeLabel: (tooltipItem) => {
            return `${tooltipItem.datasetIndex || 0}`;
          },
          afterLabel: (tooltipItem, data) => {
            if (data.datasets[tooltipItem.datasetIndex].yAxisID === PRIMARY_AXIS_ID) {
              return `${formatValueExpressionValue(primaryValueExpression, tooltipItem.yLabel, 0)}`;
            } else {
              return `${formatValueExpressionValue(compareValueExpression, tooltipItem.yLabel)}`;
            }
          },
          labelColor: (tooltipItem, chart) => {
            return chart.config.data.datasets[tooltipItem.datasetIndex] as ChartTooltipLabelColor;
          },
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            return dataset.label;
          },
        },
        custom: chartCustomFnFactory(
          (tooltipModel) => {
            setTooltipModel(tooltipModel);
          },
          (p) => setTooltipPosition(p)
        ),
      },
      onHover: chartHoverFnFactory(onActiveTooltipIndex, (p) => setTooltipPosition(p)),
    };
  };

  const _getChartData = () => {
    const { from, to } = ChartUtils.getDateRange(duration);
    const dateRanges = ChartUtils.getDateRanges(from, to);
    const comparisonRange = ChartUtils.getComparisonDateRange(duration);
    const compareRanges = ChartUtils.getDateRanges(comparisonRange.from, comparisonRange.to);

    if (!primaryValueExpression || isLoading) {
      return;
    }

    const primaryData = isPrimaryGqlExpression ? gqlPrimaryValueExpressionData?.rangeData : wsData?.primary;
    const comparisonData = isComparisonGqlExpression ? gqlComparisonValueExpressionData?.rangeData : wsData?.compare;

    const datasets = (() => {
      const mapDataFn = (isComparisonData?: boolean) => {
        return dateRanges.dates.map((dt) => {
          const tourDateKey =
            dt.format('YYYY-MM-DD') + '_' + MetricsDataProvider.translateMomentRangeToGrouping(dateRanges.resolution);
          return get(isComparisonData ? comparisonData : primaryData, tourDateKey, null);
        });
      };

      const primaryDatasets = [
        {
          yAxisID: PRIMARY_AXIS_ID,
          primary: true,
          label: primaryValueExpression.getLabel(),
          type: 'line',
          fill: false,
          lineTension: 0,
          pointRadius: 3,
          pointBorderWidth: 2,
          pointBackgroundColor: '#FFFFFF',
          spanGaps: true,
          borderColor: '#0091cd',
          backgroundColor: 'rgba(0, 145, 205, 1)',
          data: mapDataFn(),
        },
      ];

      if (!compareValueExpression || !comparisonData) {
        return primaryDatasets;
      }

      const compareDatasets = [
        {
          yAxisID: COMPARE_AXIS_ID,
          primary: true,
          type: 'bar',
          fill: false,
          label: compareValueExpression.getLabel(),
          lineTension: 0,
          pointRadius: 3,
          pointBorderWidth: 2,
          pointBackgroundColor: '#FFFFFF',
          spanGaps: true,
          borderColor: '#b2b5ba',
          backgroundColor: 'rgba(178, 181, 186, 1)',
          data: mapDataFn(true),
        },
      ];
      return [...compareDatasets, ...primaryDatasets].reverse();
    })();

    return {
      labels: dateRanges.axisLabels,
      dataLabels: dateRanges.dataLabels,
      compareDataLabels: compareRanges.dataLabels,
      labelValues: dateRanges.dates,
      datasets,
    };
  };

  const _onLegendItemClick = (legendItem) => {
    const ci = chartRef?.current?.chartInstance;
    const meta = ci.getDatasetMeta(legendItem.legendIndex);
    meta.hidden = meta.hidden === null ? !ci.data.datasets[legendItem.legendIndex].hidden : null;
    ci.update();
  };

  const _onLegendItemToggleAll = (datasets, hide: boolean) => {
    const ci = chartRef?.current?.chartInstance;
    datasets.forEach((ds, legendIndex) => {
      const meta = ci.getDatasetMeta(legendIndex);
      meta.hidden = hide;
    });
    ci.update();
  };

  const _onPrimaryValueExpressionSelect = (veKey: string) => {
    componentOptionsToState({ primaryValueExpressionKey: veKey });
  };

  const _onCompareValueExpressionSelect = (veKey: string) => {
    componentOptionsToState({ compareValueExpressionKey: veKey });
  };

  const _swap = () => {
    componentOptionsToState({
      primaryValueExpressionKey: compareValueExpressionKey,
      compareValueExpressionKey: primaryValueExpressionKey,
    });
  };

  const _removeCompare = () => {
    componentOptionsToState({ compareValueExpressionKey: '' });
  };

  const _onDurationSelected = (duration: Duration): void => {
    componentOptionsToState({ duration });
  };

  const componentOptionsToState = (update) => {
    if (!uID) {
      return;
    }

    const newOptions = { ...options, ...update };
    setComponentsOptions(uID, 'kennzahlenVergleich', newOptions);
    const newTableOptions = { ...tableOptions, ...update };
    setComponentsOptions(uID, 'kennzahlenTable', newTableOptions);
  };

  const chartLoading = () => {
    return (
      <div
        style={{
          overflow: 'hidden',
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}>
        <div className={Loader.loaderwrapper}>
          {!primaryValueExpressionKey && (
            <EmptyKpiSelectionInfo
              arrowPosition={[80, 20]}
              details={
                'Daraufhin wird die gewählte Kennzahl sowohl in der Grafik als auch in\n' +
                'der Tabelle unten dargestellt. Es besteht die Möglichkeit, eine zweite\n' +
                'Kennzahl auszuwählen, um beide Kennzahlen miteinander zu vergleichen.'
              }
            />
          )}
          {primaryValueExpressionKey && (
            <React.Fragment>
              <div>
                <img src={LoadingChart} alt='' />
              </div>
              <div className={Loader.textLoading}>Daten werden geladen...</div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const _chartData = (chartData: { datasets: any[] }, ve: ValueExpression) => {
    const chartOptions = _getChartOptions((idx: number) => {
      setActiveTooltipIndex(idx);
    });
    const { valueType } = ve;

    const legend = (
      <div className='col-md-4'>
        <div>
          <DetailedChartLegend
            datasets={chartData.datasets}
            onLegendItemToggleAll={_onLegendItemToggleAll}
            onLegendItemClick={_onLegendItemClick}
          />
        </div>
      </div>
    );

    return (
      <Row>
        <div
          className={classNames({
            'col-md-12': valueType === ValueType.single,
            'col-md-8': valueType !== ValueType.single,
          })}
          style={{ position: 'relative' }}>
          <DetailedChartTooltip
            target={chartRef.current}
            model={tooltipModel}
            activeTooltipIndex={activeTooltipIndex}
            position={tooltipPosition}
            xOffset={10}
            compareFallbackColor={'rgba(178, 181, 186, 1)'}
          />
          <div style={{ position: 'relative' }} className={Styles.ChartContainer}>
            <Bar ref={chartRef} data={chartData} options={chartOptions} />
          </div>
        </div>
        {valueType === ValueType.map && legend}
      </Row>
    );
  };

  const chartData = _getChartData();

  return (
    <div className={Styles.DetailedChart} style={{ ...style }}>
      {showDateSelector && (
        <div className={Styles.Header}>
          <div className={Styles.KpiSelectorContainer}>
            <KpiSelector
              onSelect={_onPrimaryValueExpressionSelect}
              selectedValueExpressionKeys={[primaryValueExpressionKey, compareValueExpressionKey]}
              placement='bottom-start'
            />
            <Button
              size='sm'
              className={Styles.Btn}
              color='primary'
              onClick={_swap}
              disabled={!compareValueExpressionKey}>
              <MdCompareArrows size={24} />
            </Button>
            <InputGroup>
              <KpiSelector
                onSelect={_onCompareValueExpressionSelect}
                selectedValueExpressionKeys={[compareValueExpressionKey, primaryValueExpressionKey]}
                dotColor='#b2b5ba'
                placement='bottom-start'
              />
              {compareValueExpressionKey && (
                <Button color='link' size='sm' outline className={Styles.KpiRemove} onClick={_removeCompare}>
                  <MdHighlightOff size={20} />
                </Button>
              )}
            </InputGroup>
          </div>
          <div>
            <DateSelect onDurationSelected={_onDurationSelected} selectedDuration={duration} />
          </div>
        </div>
      )}
      {chartData && (
        <div className={Styles.ChartKpis}>
          <div>
            <DetailedChartKpiValue
              orgKey={orgKey}
              duration={duration}
              valueExpressionKey={primaryValueExpression.identifier}
              selected
              customLabel={primaryValueExpression.getLabel()}
              showDetails={false}
              showDot
              customColor='#0091cd'
              rawData={{ primary: gqlPrimaryValueExpressionData?.summaryData }}
            />
            {compareValueExpression && compareValueExpressionKey && (
              <DetailedChartKpiValue
                orgKey={orgKey}
                duration={duration}
                valueExpressionKey={compareValueExpressionKey}
                customLabel={compareValueExpression.getLabel()}
                customColor='#b2b5ba'
                showDot
                showDetails={false}
                rawData={{ primary: gqlComparisonValueExpressionData?.summaryData }}
              />
            )}
          </div>
        </div>
      )}
      {chartData ? _chartData(chartData, primaryValueExpression) : chartLoading()}
    </div>
  );
};

export default KpiCompareChart;
