import { useCallback, useMemo, useState } from 'react';
import SignatureFlaggingService from '../services/SignatureFlaggingService';
import { useIsMounted } from '../../dashboard/hooks/useIsMounted';
import { NetworkError } from '../errors/NetworkError';
import { NotFoundError } from '../errors/NotFoundError';
import { DataProtectError } from '../errors/DataProtectionError';
import { NO_OP } from '../../dashboard/components/FilterBubble';

export const useApiImageFetch = (url: string, handleImageError?: () => void) => {
  const isMounted = useIsMounted();
  const [error, setError] = useState<Error | null>(null);
  const [imgId, setImageId] = useState(null);

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const loadImageFunctionFactory = useCallback(
    (url: string) => {
      if ((url || '').startsWith('blob')) {
        return async () => {
          return url;
          /**/
        };
      }

      return () => {
        return fetch(url, {
          method: 'get',
        })
          .then((resp) => {
            if (!isMounted.current) {
              return;
            }

            if (resp.status === 200) {
              if (resp.headers.get('content-disposition')) {
                const imageId = SignatureFlaggingService.extractImageId(resp.headers.get('content-disposition'));
                if (isMounted.current) {
                  setError(null);
                  setImageId(imageId);
                }
              }
              return resp.blob();
            }
            if ([451, 409].includes(resp.status)) {
              throw new DataProtectError();
            }
            if (resp.status === 404) {
              // 404 errors usually occur after 6 months as old images get deleted from server
              throw new NotFoundError();
            }
            throw new Error('Unbekannter Fehler');
          })
          .then((blob) => {
            if (!isMounted.current) {
              return '';
            }

            return URL.createObjectURL(blob);
          })
          .catch((e) => {
            if (!isMounted.current) {
              return;
            }
            handleImageError && handleImageError();
            if (e.message === 'Failed to fetch') {
              setError(new NetworkError(e));
            } else {
              setError(e);
            }
            return '';
          });
      };
    },
    [handleImageError, isMounted]
  );

  const loadFn = useMemo(() => {
    return loadImageFunctionFactory(url);
  }, [loadImageFunctionFactory, url]);

  return {
    loadFn,
    error,
    imgId,
    resetError,
  };
};
