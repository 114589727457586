import React, { ReactElement } from 'react';
import { withTourenReportAction, TourenReportActionProps } from './with-touren-report.hoc';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { RsTeZe } from '../../../../modules/tour/models/entities/RsTeZe';
import FinishedDelivery from '../../../../modules/tour/models/entities/FinishedDelivery';
import TourenReportIcons, { getPdfIconByName } from './touren-report-icons';
import { DeliveryItemTrackingData } from '../../../../modules/tour/models/types/DeliveryItemTrackingData';

type ServiceIconName =
  | 'wunschtag'
  | 'wunschzeitfenster'
  | 'eilservice'
  | 'identservice'
  | 'nachnahme'
  | 'verbotnachbarschaftsabgabe'
  | 'colli'
  | 'bulky'
  | 'valuable'
  | 'number'
  | 'email'
  | 'luggage';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  text: {
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '15px 15px',
  },
  headerText: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '14px',
  },
  subheader: {
    borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 15px',
  },
  subHeaderText: {
    fontFamily: 'Helvetica-Bold',
    fontSize: '12px',
    marginRight: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: '1px solid black',
    overflow: 'hidden',
  },
  column: {
    padding: '10px 15px',
    flex: '1 1 0%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  shrinkColumn: {
    flex: '0 1 60px',
  },
  statusContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  capitalize: { textTransform: 'capitalize' },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '15px',
  },
  legendRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: '20px',
  },
  legendEntryText: { marginLeft: '5px' },
});

function TourenReport({
  orgUnit,
  tourIdentifier,
  finishedDeliveries,
  tourDetails,
  signatureMap,
}: TourenReportActionProps): ReactElement {
  return (
    <Document
      language='de'
      title='Last Mile Analytics - Tourenbericht'
      author='Last Mile Analytics'
      creator='Last Mile Analytics'
      producer='Last Mile Analytics'>
      <Page size='A4' dpi={148} style={styles.page}>
        <View style={styles.header} fixed={true}>
          <Text style={{ ...styles.text, ...styles.headerText }}>Tourenbericht</Text>
          <Text
            style={{ ...styles.text, marginLeft: 'auto' }}
            render={({ pageNumber, totalPages }) => {
              return `Seite ${pageNumber} von ${totalPages}`;
            }}
          />
        </View>
        <View style={styles.subheader} fixed={true}>
          <Text style={{ ...styles.text, ...styles.subHeaderText }}>
            Organisationseinheit-ID: {tourIdentifier.orgId} ({orgUnit?.orgUnit?.name})
          </Text>
          <Text style={{ ...styles.text, ...styles.subHeaderText }}>Tour: {tourIdentifier.number}</Text>
          <Text style={{ ...styles.text, ...styles.subHeaderText }}>
            Tourdatum: {moment(tourIdentifier.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={{ ...styles.column, ...styles.shrinkColumn, ...styles.text }}>TE-ZE</Text>
          <Text
            style={{
              ...styles.column,
              ...styles.text,
            }}>
            Kundendaten
          </Text>
          <Text
            style={{
              ...styles.column,
              ...styles.shrinkColumn,
              ...styles.text,
            }}>
            SDG
          </Text>
          <Text
            style={{
              ...styles.column,
              ...styles.text,
            }}>
            Unterschrift
          </Text>
          <Text
            style={{
              ...styles.column,
              ...styles.text,
            }}>
            Barcode
          </Text>
          <Text style={{ ...styles.column, ...styles.text, flex: '0 1 100px' }}>Status</Text>
          <Text
            style={{
              ...styles.column,
              ...styles.text,
            }}>
            Auftraggeber
          </Text>
          <Text style={{ ...styles.column, ...styles.text, flex: '0 1 200px' }}>BNK-Barcode</Text>
          <Text style={{ ...styles.column, ...styles.text, flex: '0 1 100px' }}>Services</Text>
        </View>
        {finishedDeliveries?.map((finishedDelivery) => (
          <TourenReportRow
            tourDetails={tourDetails}
            finishedDelivery={finishedDelivery}
            signatureMap={signatureMap}
            key={finishedDelivery.uuid}
          />
        ))}
        <View fixed={true} style={styles.legendContainer}>
          <View style={styles.legendRow}>
            <TourenReportIcons.BNK />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>BNK</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Email />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>BNK: Email</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Bulky />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Bulky</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Eil />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Eilservice</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.IdentService />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Identservice</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Luggage />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>DB Gepäck</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Mehrcollig />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Mehrcollig</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Nachnahme />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Nachnahme</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.Valuable />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Wertsendung</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.VerbotNachbarschaftsAbgabe />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Nachbarschaftsabgabeverbot</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.WunschTag />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Wunschtag</Text>
          </View>
          <View style={styles.legendRow}>
            <TourenReportIcons.WunschzeitFenster />
            <Text style={{ ...styles.text, ...styles.legendEntryText }}>Wunschzeitfenster</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default withTourenReportAction(TourenReport);

type TourenReportRowProps = Pick<TourenReportActionProps, 'tourDetails' | 'signatureMap'> & {
  finishedDelivery: FinishedDelivery;
};
function TourenReportRow({ tourDetails, finishedDelivery, signatureMap }: TourenReportRowProps): ReactElement {
  const customerDelivery = Object.values(finishedDelivery?.customerDeliveries ?? {})?.[0];
  const deliveryItems: DeliveryItemTrackingData[] = [
    ...(finishedDelivery?.deliveryItems ?? []),
    ...(finishedDelivery?.returnDeliveryItems ?? []),
  ];
  const { firstName, lastName, street, streetNr, city, zipcode, rsteze, salutation } =
    tourDetails?.customers?.get(customerDelivery?.customerRef) ?? {};
  const sendungsTypes = Array.from(new Set(deliveryItems?.map((di) => di?.itemInfo?.itemType ?? 'UNB') ?? []).values());
  const rstezeEntity = rsteze ? RsTeZe.parseFromString(rsteze) : null;
  const tezeFormatted = rstezeEntity ? [rstezeEntity?.te, rstezeEntity?.ze].join('-') : '---';
  const sendungsNummern = Array.from(new Set(deliveryItems?.map((di) => di?.id) ?? []).values());
  const status = deliveryItems?.[0]?.status;
  const createdAt = finishedDelivery?.finishedAt;
  const auftraggeber = Array.from(new Set(deliveryItems?.map((di) => di?.itemInfo?.client) ?? []).values());

  const serviceIcons = Array.from(
    new Set(
      deliveryItems?.flatMap((di) => {
        const serviceIconNames: ServiceIconName[] = [];
        const { wunschzeitFenster, eilservice, identservice, nachnahme, verbotNachbarschaftsabgabe } =
          di?.service ?? {};
        const { colli, bulky, valuable, luggage } = di?.itemInfo ?? {};
        const isWunschzeitFenster = wunschzeitFenster && wunschzeitFenster.from && wunschzeitFenster.to;
        const isWunschtag = wunschzeitFenster && Object.keys(wunschzeitFenster).length === 1;
        const EMAIL_BNK = '000000000000';
        isWunschtag && serviceIconNames.push('wunschtag');
        isWunschzeitFenster && serviceIconNames.push('wunschzeitfenster');
        !!eilservice && serviceIconNames.push('eilservice');
        !!identservice && serviceIconNames.push('identservice');
        !!nachnahme && serviceIconNames.push('nachnahme');
        !!verbotNachbarschaftsabgabe && serviceIconNames.push('verbotnachbarschaftsabgabe');
        !!colli && serviceIconNames.push('colli');
        !!bulky && serviceIconNames.push('bulky');
        !!valuable && serviceIconNames.push('valuable');
        !!luggage && serviceIconNames.push('luggage');
        !!customerDelivery?.notifyCustomer &&
          di?.deliveryItemClass !== 'ReturnParcel' &&
          (finishedDelivery?.deliveryMode !== 'person' || customerDelivery?.recipientPerson === 'nextDoor') &&
          serviceIconNames.push(customerDelivery?.notificationInfo === EMAIL_BNK ? 'email' : 'number');
        return serviceIconNames;
      })
    ).values()
  )?.map((serviceIconName) => getPdfIconByName(serviceIconName));
  return (
    <View style={styles.row} wrap={false}>
      <Text style={{ ...styles.column, ...styles.shrinkColumn, ...styles.text }}>{tezeFormatted}</Text>
      <View style={{ ...styles.column }}>
        {firstName && lastName ? (
          <>
            <Text style={{ ...styles.text, ...styles.capitalize }}>
              {salutation?.length > 1 && `${salutation?.toLowerCase()} `}
              {firstName?.toLowerCase()} {lastName?.toLowerCase()}
            </Text>
          </>
        ) : (
          <Text style={styles.text}>Unbekannt</Text>
        )}
        {street && streetNr && city && zipcode ? (
          <>
            <Text style={{ ...styles.text, ...styles.capitalize }}>
              {street?.toLowerCase()} {streetNr}
            </Text>
            <Text style={{ ...styles.text, ...styles.capitalize }}>
              {zipcode} {city?.toLowerCase()}
            </Text>
          </>
        ) : (
          <Text style={styles.text}>Keine Adresse vorhanden</Text>
        )}
      </View>
      <View
        style={{
          ...styles.column,
          ...styles.shrinkColumn,
        }}>
        {sendungsTypes?.length > 0 ? (
          sendungsTypes?.map((sendungsType) => (
            <Text style={styles.text} key={sendungsType}>
              {sendungsType}
            </Text>
          ))
        ) : (
          <Text style={styles.text}>UNB</Text>
        )}
      </View>
      <View
        style={{
          ...styles.column,
          justifyContent: 'center',
          alignItems: 'stretch',
          minHeight: '150px',
        }}>
        {signatureMap?.get(finishedDelivery?.displayableStopNumber?.toString())}
      </View>
      <View style={{ ...styles.column }}>
        {sendungsNummern?.map((sendungsNummer) => (
          <Text style={styles.text} key={sendungsNummer}>
            {sendungsNummer}
          </Text>
        ))}
        {finishedDelivery?.entitled && (
          <>
            <Text style={{ ...styles.text, marginTop: '10px' }}>Berechtigter:</Text>
            <Text style={styles.text}>{finishedDelivery?.entitled}</Text>
          </>
        )}
      </View>
      <View style={{ ...styles.column, flex: '0 1 100px' }}>
        {<Text style={styles.text}>{status ?? 'Unbekannt'}</Text>}
        {createdAt && <Text style={styles.text}>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</Text>}
      </View>
      <View style={styles.column}>
        {auftraggeber?.length > 0 ? (
          auftraggeber?.map((auftraggeber) => (
            <Text style={styles.text} key={auftraggeber}>
              {auftraggeber}
            </Text>
          ))
        ) : (
          <Text style={styles.text}>Auftraggeber unbekannt</Text>
        )}
      </View>
      <Text style={{ ...styles.column, ...styles.text, flex: '0 1 200px' }}>{customerDelivery?.notificationInfo}</Text>
      <View
        style={{
          ...styles.column,
          flexDirection: 'row',
          flexWrap: 'wrap',
          flex: '0 1 100px',
          alignContent: 'flex-start',
        }}>
        {serviceIcons}
      </View>
    </View>
  );
}
