import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';
import touren from './touren';

const tourfreigabeZeitpunktSekunden = new SingleValueExpression(
  new MetricType({
    key: MetricTypeKey.TourfreigabeZeitpunkt,
    label: 'Uhrzeit Tourfreigabe',
    aggregation: AggregationType.sum,
    chart: ChartType.line,
    positiveDirection: PositiveDirection.custom,
    valueFormat: 'hh:mm',
    category: 'tours',
    definition: getDefinitionByName('Uhrzeit Tourfreigabe'),
    nullValue: '-',
  }),
  'Tourfreigabe'
);

const tourfreigabeZeitpunktStunden = new QuotientValueExpression(
  tourfreigabeZeitpunktSekunden,
  new StaticValueExpression(3600)
);

const avgTourfreigabeZeitpunkt = new QuotientValueExpression(
  tourfreigabeZeitpunktStunden,
  touren,
  'Uhrzeit Tourfreigabe',
  false,
  'hh:mm',
  null,
  getDefinitionByName('Uhrzeit Tourfreigabe')
);
avgTourfreigabeZeitpunkt.setIdentifier('tourfreigabe_zeitpunkt/touren');
avgTourfreigabeZeitpunkt.nullValue = '-';

export default avgTourfreigabeZeitpunkt;
