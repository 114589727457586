import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoHtZeit from './netto-ht-zeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  nettoHtZeit,
  touren,
  'Ø Netto-HT-Zeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Netto-HT-Zeit')
);

expression.nullValue = '-';
expression.setIdentifier('netto-ht-zeit/touren');

export default expression;
