import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoPsZeit from './netto-ps-zeit';
import touren from './touren';

const expression = new QuotientValueExpression(
  nettoPsZeit,
  touren,
  'Ø Netto-PS-Zeit',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Netto-PS-Zeit')
);

expression.nullValue = '-';
expression.setIdentifier('netto-ps-zeit/touren');

export default expression;
