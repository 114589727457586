import WebsocketApiService from './WebsocketApiService';
import WebsocketEvent from '../../common/models/websocket/WebsocketEvent';
import AuthStatusPayload from '../models/websocket/payloads/auth/AuthStatusPayload';
import ExtendedEventEmitter from '../../events/ExtendedEventEmitter';
import { localStorageService } from './LocalStorageService';
import Logger from '../../utils/logging/Logger';
import Keycloak from 'keycloak-js';
import TrackingService from '../../tracking/services/TrackingService';
import AuthLoginPayload from '../models/websocket/payloads/auth/AuthLoginPayload';
import toObject from '../../utils/toObject/toObject';
import Permissions from '../models/auth/Permissions';
import { EnvironmentConfigurationService } from './EnvironmentConfigurationService';
import { CountryUtils } from '@legacy-modules/utils/tours/CountryUtils';
import {
  lmaReduxStore,
  selectAuth,
  AuthState,
  selectRootOrgKeys,
  dashboardSlice,
  overviewSlice,
  authSlice,
} from '@redux';

export enum AuthEvent {
  EVENT_LOGGED_IN = 'auth.loggedIn',
  EVENT_LOGGED_OUT = 'auth.loggedOut',
  REQUEST_LOGIN = 'auth.login',
  REQUEST_REFRESH = 'auth.refresh',
}

export default class AuthService {
  authStateProvider: () => AuthState;
  websocketApiService: WebsocketApiService;
  trackingService: TrackingService;
  _eventEmitter: ExtendedEventEmitter;
  logger: Logger;
  keycloak: Keycloak.KeycloakInstance;
  tokenRefresh: number;

  constructor(websocketApiService: WebsocketApiService, trackingService: TrackingService) {
    this.authStateProvider = () => selectAuth(lmaReduxStore.getState());
    this._eventEmitter = new ExtendedEventEmitter();
    this.websocketApiService = websocketApiService;
    this.trackingService = trackingService;
    // eslint-disable-next-line new-cap
    const { url, realm, resource } = EnvironmentConfigurationService.getKeycloakConfiguration();

    this.keycloak = Keycloak({
      url: url,
      realm: realm,
      clientId: resource,
    });
  }

  async init() {
    this.logger = Logger.getInstance('AuthService');
    this.onSocketConnected = this.onSocketConnected.bind(this); // $FlowFixMe
    this.onSocketDisconnected = this.onSocketDisconnected.bind(this); // $FlowFixMe
    this.websocketApiService.eventEmitter.on(WebsocketApiService.EVENT_SOCKET_OPENED, this.onSocketConnected);
    this.websocketApiService.eventEmitter.on(WebsocketApiService.EVENT_SOCKET_CLOSED, this.onSocketDisconnected);
    this.websocketApiService.eventEmitter.on(WebsocketApiService.EVENT_SOCKET_ERROR, this.onSocketDisconnected);
    this.websocketApiService.eventEmitter.on(
      WebsocketApiService.EVENT_SOCKET_AUTH_EXPIRED,
      this.refreshToken.bind(this)
    );
    await this._setAuthStateFromStorage();
  }

  async _setAuthStateFromStorage() {
    const authData = localStorageService.get<AuthState>('authState');
    if (authData?.token) {
      authSlice.actions.setToken(authData.token);
    }
  }

  get isAuthenticated(): boolean {
    return this.authStateProvider().connected && this.authStateProvider().loggedIn;
  }

  get rootOrgKeys() {
    return this.authStateProvider().rootOrgKeys;
  }

  async _initKeyCloak(): Promise<void> {
    this.logger.info('AuthService', 'Trying to init Keycloak.');
    this.keycloak.onAuthSuccess = this.authenticateWithBackend.bind(this);
    this.keycloak.onAuthRefreshSuccess = this.refreshBackend.bind(this);
    this.keycloak.onTokenExpired = this.refreshToken.bind(this);
    const options = {
      onLoad: 'login-required' as const,
      promiseType: 'native',
      checkLoginIframe: false,
    };

    return new Promise((resolve, reject) => {
      this.keycloak.init(options).then(async (authenticated) => {
        this.logger.info('Initialized keycloak. Authenticated? ' + authenticated);
        if (authenticated) {
          lmaReduxStore.dispatch(authSlice.actions.setToken(this.keycloak.token));
          this.logger.info('AuthService', 'Initialized in _initKeyCloak', this.authStateProvider().initialized);
          resolve();
        } else {
          this.logger.error('AuthService', 'Failed to init Keycloak.');
          reject(new Error('Failed to init Keycloak'));
        }
      });
    });
  }

  async onSocketConnected() {
    this.logger.info(
      'AuthService',
      'Initialized onSocketConnected',
      'Initialized: ' + this.authStateProvider().initialized
    );
    lmaReduxStore.dispatch(authSlice.actions.connect());
    if (!this.authStateProvider().initialized) {
      try {
        await this._initKeyCloak();
        if (this.tokenRefresh == null) {
          this.logger.info('AuthService', 'Setting window timer to refresh token');
          this.tokenRefresh = window.setInterval(this.refreshToken.bind(this), 5000);
        }
      } catch (e) {
        await this.logout();
      }
    } else {
      await this.authenticateWithBackend();
    }
  }

  refreshToken() {
    this.keycloak
      .updateToken(60)
      .then((refreshed) => refreshed && lmaReduxStore.dispatch(authSlice.actions.setToken(this.keycloak.token)))
      .catch(() => this.logger.warn('AuthService refreshToken failure'));
  }

  onSocketDisconnected() {
    lmaReduxStore.dispatch(authSlice.actions.disconnect());
  }

  async refreshBackend(): Promise<boolean> {
    return this.sendAuthenticationRequestToBackend(
      new AuthLoginPayload({ token: this.keycloak.token }),
      AuthEvent.REQUEST_REFRESH
    );
  }

  async authenticateWithBackend(): Promise<boolean> {
    return this.sendAuthenticationRequestToBackend(
      new AuthLoginPayload({ token: this.keycloak.token }),
      AuthEvent.REQUEST_LOGIN
    );
  }

  // ideally we would like to update token contents after every refresh
  // but combined with current approach in other components this leads to re-render
  // and possibly changing of the visible contents
  async sendAuthenticationRequestToBackend(payload: AuthLoginPayload, name: string): Promise<boolean> {
    if (this.authStateProvider().connected) {
      try {
        return await this.talkToBackend(payload, name);
      } catch (e) {
        const msg = e == null ? e : e.message;
        // eslint-disable-next-line no-console
        console.error(e);
        this.logger.error('Error while authenticating.', { msg });
      }
    }
    this.logger.info('Could not authenticate to backend, retrying in 10 seconds');
    window.setTimeout(() => this.sendAuthenticationRequestToBackend(payload, name), 10000);
    return false;
  }

  async talkToBackend(payload: AuthLoginPayload, name: string): Promise<boolean> {
    const isLoginAttempt = name === AuthEvent.REQUEST_LOGIN;
    const response = await this.websocketApiService.request(new WebsocketEvent({ name: name, payload }));
    if (response.name === 'system.error') {
      const payload = response.payload;
      if (payload) {
        const message = payload.error === 403 ? null : `[${payload.error}] ${payload.message}`;
        const reloginToBackend = payload.error === 401;
        if (isLoginAttempt) {
          await this._trackLogin(false, message);
          await this.logout();
          return false;
        } else if (reloginToBackend) {
          return this.authenticateWithBackend();
        }
        return true;
      }
      return false;
    } else if (response.payload instanceof AuthStatusPayload) {
      if (isLoginAttempt) {
        return this._processAuthResponse(response.payload);
      }
      return true;
    } else {
      await this._trackLogin(false, 'Unknown response payload');
      this.logger.error('AuthService LOGOUT unknown payload type', payload);
      return false;
    }
  }
  async _processAuthResponse(payload: AuthStatusPayload): Promise<boolean> {
    if (payload.loggedIn) {
      lmaReduxStore.dispatch(authSlice.actions.setLoggedIn(true));
      lmaReduxStore.dispatch(authSlice.actions.setTokenId(payload.tokenId));
      if (!this.authStateProvider().initialized) {
        lmaReduxStore.dispatch(authSlice.actions.setUserDetails(payload.userDetails));
        lmaReduxStore.dispatch(authSlice.actions.setPermissions(payload.permissions || []));
        const rootOrgKeys = payload.rootOrgKeys;
        const defaultRootOrgKey = payload.rootOrgKey;
        const overview = rootOrgKeys?.overview || defaultRootOrgKey;
        const dashboard = rootOrgKeys?.dashboard || defaultRootOrgKey;
        const stoppdetail = rootOrgKeys?.stoppdetail || defaultRootOrgKey;
        lmaReduxStore.dispatch(
          authSlice.actions.setRootOrgKeys({
            overview,
            dashboard,
            stoppdetail,
          })
        );
        lmaReduxStore.dispatch(overviewSlice.actions.setRootOrgKey(overview));
        lmaReduxStore.dispatch(overviewSlice.actions.updateOrgKeys([]));
        lmaReduxStore.dispatch(dashboardSlice.actions.setOrgKey(dashboard));

        localStorageService.set('authState', toObject(this.authStateProvider()));

        lmaReduxStore.dispatch(authSlice.actions.setInitialized(true));
        this._eventEmitter.emit(AuthEvent.EVENT_LOGGED_IN);
        await this._trackLogin(true);
      }
    } else {
      await this._trackLogin(false);
      await this.logout();
    }
    return this.authStateProvider().loggedIn;
  }

  async _trackLogin(success: boolean, error: string | null = null): Promise<void> {
    await this.trackingService.sendEvent('last-mile-analytics.auth.login', {
      loginInfo: { success, error },
    });
  }

  async logout(): Promise<void> {
    this.logger.info(`Logging user '${this.authStateProvider().userDetails.username}' out`);
    window.clearInterval(this.tokenRefresh);
    delete this.tokenRefresh;
    if (this.isAuthenticated) {
      await this.trackingService.sendEvent('last-mile-analytics.auth.logout');
    }
    lmaReduxStore.dispatch(authSlice.actions.logout());
    localStorageService.remove('authState');
    this._eventEmitter.emit(AuthEvent.EVENT_LOGGED_OUT);
    this.keycloak.logout();
  }

  can(permission: string): boolean {
    const permissions = new Permissions();
    permissions.hydrateFrom(this.authStateProvider().permissions);
    return permissions.can(permission);
  }

  cannot(permission: string): boolean {
    return !this.can(permission);
  }

  // check if an internal employee has rights to access certain visibilities
  // such as stop details or signatures
  canAccessStop(orgPath: string[]): boolean {
    const stoppdetail = selectRootOrgKeys(lmaReduxStore.getState())?.stoppdetail;

    // no stop access allowed anywhere, for example marketing employees
    if (!orgPath || !stoppdetail) {
      return false;
    }

    // access to every stop, for example devs or project leads
    if (stoppdetail?.some((key) => CountryUtils.isCountry(key))) {
      return true;
    }

    // access to stops of tours belonging to certain org, in case stopp detail root key
    // represents a certain area (oa) or zsb (oz)
    return orgPath.some((orgKey) => stoppdetail?.includes(orgKey));
  }

  get eventEmitter() {
    return this._eventEmitter;
  }
}
