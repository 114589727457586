import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import abholungen from './abholungen';
import touren from './touren';

const expression = new QuotientValueExpression(
  abholungen,
  touren,
  'Ø Abholungen',
  false,
  undefined,
  undefined,
  getDefinitionByName('Abholungen')
);

expression.nullValue = '-';
expression.setIdentifier('abholungen/touren');

export default expression;
