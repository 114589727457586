import SumValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SumValueExpression';
import lademengeInclNichtGestartet from './lademenge-incl-nicht-gestartet';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import { MetricTypeKey } from './metric-type-keys';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';

const expression = new SumValueExpression(
  new StaticValueExpression(0), // TODO: check why this is required
  lademengeInclNichtGestartet,
  'SOLL-Lademenge',
  undefined,
  '-#.###.##0',
  getDefinitionByName('SOLL-Lademenge')
);

expression.chartType = ChartType.bar;
expression.nullValue = '-';
expression.setIdentifier(MetricTypeKey.TAsum);

export default expression;
