import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import anzahlTagZsbPlus0ExpressSuccessful from './anzahl-tag-zsb-plus-0-express-successful';
import anzahlTagZsbPlus0Express from './anzahl-tag-zsb-plus-0-express';

const expression = new QuotientValueExpression(
  anzahlTagZsbPlus0ExpressSuccessful,
  anzahlTagZsbPlus0Express,
  'ZSB+0 Eil',
  true,
  undefined,
  undefined,
  getDefinitionByName('ZSB+0 Eil')
);
expression.nullValue = '-';
expression.chartRenderOptions.startYAxisOnZero = false;
expression.setIdentifier('zsb_plus_0_express');

export default expression;
