import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import ruecklaufer from './ruecklaufer';
import touren from './touren';

const expression = new QuotientValueExpression(ruecklaufer, touren, 'Ø Rückläufer');

expression.nullValue = '-';
expression.setIdentifier('ruecklaeufer/touren');

export default expression;
