export type ChangeType = {
  features?: string[];
  bugs?: string[];
  improvements?: string[];
  info?: string[];
};

export type VersionData = {
  [version: string]: {
    releaseDate: string;
    changes: ChangeType;
  };
};

/* eslint-disable max-len */
const versionData: VersionData = {
  '2.4.0': {
    releaseDate: '24.4.2018',
    changes: {
      features: [
        'Einige Anzeigewerte wurden zum besseren Verständnis umbenannt.',
        'Die Liste der Anzeigewerte ist jetzt in Kategorien unterteilt',
        'Die Anzeigewerte sind neu sortiert.',
        'Für Segmente, Anzeigewerte und die Kategorien werden jetzt Beschreibungstexte angezeigt.',
        'Die Formatierung der Anzeigewerte wurde überarbeitet.',
      ],
    },
  },
  '2.4.1': {
    releaseDate: '26.4.2018',
    changes: {
      features: [
        'Für Feedback rund um LM:A können Sie jetzt auf den Feedback-Button (Briefumschlag) oben rechts klicken' +
          ' oder eine E-Mail an last-mile-analytics@hermesworld.com senden.',
        'Der Wert "Sendungen nach Sendungsart" ist jetzt verfügbar.',
      ],
      bugs: ['Fehler bei der Anzeige von "Sendungen pro Stunde" und "Rücklaufquote" auf der Karte wurden behoben.'],
    },
  },
  '2.4.2': {
    releaseDate: '6.6.2018',
    changes: {
      features: [
        'Lesezeichen können jetzt angelegt und verwendet werden. Klicken Sie hierzu auf das kleine Lesezeichen oben links.',
        'Im Übersichtsbereich gibts es jetzt einen Button um zur jeweils höhergelegenen Organisationseben zu gelangen.',
      ],
      bugs: [
        'Fehler bei den Werten in den Tourdetails wurden behoben.',
        'Ein Fehler bei dem Stopps im Atlantik angezeigt wurden wurde behoben.',
      ],
    },
  },
  '2.4.3': {
    releaseDate: '16.7.2018',
    changes: {
      features: ['Die Kartengebiete wurden aktualisiert und sind jetzt auf dem aktuellen Stand.'],
      bugs: [
        'Datenfehler bei den Rückläuferberechnungen wurden behoben.',
        'Die fehlende Zuordnung von Zahlen zu LCs wurde behoben.',
      ],
    },
  },
  '2.4.4': {
    releaseDate: '18.7.2018',
    changes: {
      features: ['Neue Werte für Servicebeanstandungen sowie Sendungen mit Servicebeanstandung wurden hinzugefügt.'],
    },
  },
  '2.4.5': {
    releaseDate: '26.7.2018',
    changes: {
      features: [
        'Neue Werte für Kundenkontakte mit Servicebeanstandung wurden hinzugefügt.',
        'Die Kommunikation mit den Servern wurde beschleunigt.',
      ],
      bugs: [
        'Ein Fehler in der Berechnung der Tourproduktivität im Touren-Bereich wurde behoben.',
        'Ein Fehler bei dem der Start einer Tour im Atlantik lag wurde behoben.',
      ],
    },
  },
  '2.5.0': {
    releaseDate: '7.8.2018',
    changes: {
      features: [
        'Bei gruppierten Werten wie z.B. Sendungen nach Sendungsart können jetzt Einzelwerte ausgewählt werden.',
      ],
    },
  },
  '2.5.1': {
    releaseDate: '10.8.2018',
    changes: {
      features: ['Der Wert "Zustellvorgänge pro Tour" wurde hinzugefügt.'],
      bugs: [
        'Bei den Anzeigewerten "Rückläufer nach Grund" und "Sendungen nach detaillierter Zustellart" wurden fehlende Werte ergänzt.',
      ],
    },
  },
  '2.5.2': {
    releaseDate: '14.8.2018',
    changes: {
      features: [
        'Beim Vergleich von Daten ist die Rot-Grün Einfärbung jetzt in einem sinnvollen Zusammenhang zum gewählten Anzeigewert.',
        'Bei Vergleichen wird jetzt eine prozentuale Differenz angezeigt.',
      ],
    },
  },
  '2.5.3': {
    releaseDate: '23.8.2018',
    changes: {
      features: [
        'Unterhalb der Tourenelement-Ebene ist jetzt auch die Zellenelement-Ebene verfügbar',
        'Die neuen Anzeigewerte "Fläche", "Einwohner", "Sendungen pro Einwohner", "Sendungen pro km²" und "Einwohner pro km²" sind jetzt verfügbar',
      ],
    },
  },
  '2.6.0': {
    releaseDate: '13.9.2018',
    changes: {
      features: [
        'Auf Deutschland-Ebene kann jetzt zwischen Hermes Organisationen, Postleitzahlen und Richtungsschlüsseln ausgewählt werden.',
        'Auf ZSB-Ebene kann jetzt zwischen Postleitzahlen und Tourenelementen ausgewählt werden.',
        'Das Segment Delta ist jetzt verfügbar.',
      ],
    },
  },
  '2.7.0': {
    releaseDate: '27.9.2018',
    changes: {
      features: [
        'In den Tourendetails kann jetzt der Zustellungsstatus besser erkannt werden.',
        'In der Tourenliste wird jetzt die Anzahl und der Fortschritt der Touren zusammengefasst',
        'In den Tourendetails kann jetzt einfach zwischen den Touren einer ZSB navigiert werden',
        'Der Wert "Zustellquote" ist jetzt verfügbar',
        'Die Werte "Sendungen pro km²" und "Sendungen pro Einwohner" sind jetzt verfügbar',
      ],
      bugs: [
        'Es wurde ein Fehler behoben bei dem die Breadcrumb in der falschen Reihenfolge angezeigt wurde',
        'Es wurde ein Fehler behoben bei dem nicht alle Rücklaufgründe in den Tourendetails angezeigt wurden',
      ],
    },
  },
  '2.8.0': {
    releaseDate: '10.10.2018',
    changes: {
      features: [
        'Es ist jetzt möglich, Organisationen zu durchsuchen sowie mehrere gleichzeitig auszuwählen.',
        'Die Navigationsleiste sowie die Nutzbarkeit wurde optimiert.',
        'Einige neue Anzeigewerte zu ZSB Prozessen wurden hinzugefügt.',
        'Die Anzeige von negativen Werten wurde überarbeitet.',
      ],
      bugs: [
        'Es wurde ein Fehler behoben bei dem in der Touransicht im Firefox nicht gescrollt werden konnte.',
        'Es wurde ein Fehler behoben durch den manche Anzeigewerte für Kundenkontakte mit Servicebeanstandung nicht angezeigt werden konnten.',
      ],
    },
  },
  '2.8.1': {
    releaseDate: '13.10.2018',
    changes: {
      features: ['Es wurde ein Informations-Banner zum aktuellen Teradata-Ausfall hinzugefügt.'],
    },
  },
  '2.8.2': {
    releaseDate: '17.10.2018',
    changes: {
      bugs: [
        'Ein Fehler bei dem Zeiträume von 50-90 Tagen nicht angesehen werden konnten wurde behoben.',
        'Ein Anzeigefehler der Beschreibungen von Anzeigewerten wurde behoben.',
        'Ein Problem bei der Anzeige auf mobilen Endgeräten wurde behoben.',
        'Mehrere Probleme bei der Anzeige der letzten Anzeigewerte wurden behoben.',
      ],
    },
  },
  '2.8.3': {
    releaseDate: '18.10.2018',
    changes: {
      features: ['Die Seitennavigation bleibt jetzt eingeklappt wenn sie beim vorherigen Besuch eingeklappt wurde.'],
    },
  },
  '2.9.0': {
    releaseDate: '6.11.2018',
    changes: {
      features: [
        'Der neue Bereich „Sendungen“ ist jetzt verfügbar. Hier werden Daten zu einzelnen Sendungen gezeigt.',
        'Die Karte im Übersichts-Bereich kann jetzt frei bewegt und vergrößert werden.',
        'Die Auswahl-Buttons wurden mit Anzeige-Informationen belegt (beim Drüberwischen mit der Maus).',
        'In der Zeitauswahl kann jetzt das Datum über Texteingabe eingestellt werden.',
        'Die Lesezeichen-Funktion wurde verbessert.',
        'Der Bereich „Letzte Werte“ kann jetzt individuell eingeblendet oder ausgeblendet werden.',
      ],
    },
  },
  '2.10.0': {
    releaseDate: '13.11.2018',
    changes: {
      features: [
        'Die Kartengebiete wurden aktualisiert',
        'Die Darstellung im Übersichts-Bereich wurde überarbeitet',
        'Eine Einführung zu Last Mile Analytics wurde hinzugefügt',
        'Einige kleine Änderungen im Tourenbereich wurden für eine bessere Bedienbarkeit vorgenommen',
        'Die Funktion zur Gebietseinteilung in den Tourdetails wurde aktiviert',
      ],
    },
  },
  '2.10.1': {
    releaseDate: '21.11.2018',
    changes: {
      features: [
        'Im Sendungsbereich werden nah zusammen liegende Sendungen jetzt zusammengefasst',
        'Die Zahlen im Sendungsbereich werden jetzt korrekt formatiert',
      ],
      bugs: ['Interaktive Flächen wurden im Tourenbereich wieder als solche gekennzeichnet'],
    },
  },
  '2.10.2': {
    releaseDate: '23.11.2018',
    changes: {
      features: ['Die Suche nach Organisationen wurde optimiert.'],
    },
  },
  '2.11.0': {
    releaseDate: '7.12.2018',
    changes: {
      features: [
        'Eine Suchfunktion für Sendungen wurde hinzugefügt',
        'Im Sendungsbereich werden jetzt ausgewählte Filter zusammengefasst',
        'Aktuelle Hinweise werden jetzt direkt in Last Mile Analytics angezeigt',
      ],
      bugs: ['Ein Fehler bei dem im Sendungsbereich der "Zur Tour" Link nicht funktioniert hat wurde behoben'],
    },
  },
  '2.12.0': {
    releaseDate: '3.1.2019',
    changes: {
      features: [
        'Bei Filtern im Sendungsbereich kann jetzt gesucht werden',
        'Im Sendungsbereich ist jetzt eine Satellitenansicht verfügbar',
        'Es wurde eine "Gehe zu Organisation" Funktion im Sendungsbereich eingefügt',
      ],
      bugs: [
        'Ein Fehler bei dem im Tourenbereich bei laufender Zeitmaschine nicht gezoomt werden konnte wurde behoben',
      ],
    },
  },
  '2.12.1': {
    releaseDate: '18.1.2019',
    changes: {
      features: ['Bei einigen Filtern wurde eine optimierte Sortierung eingefügt.'],
      bugs: ['Ein Fehler bei dem der Sendungen-Bereich manchmal nicht geladen werden konnte wurde behoben.'],
    },
  },
  '2.12.2': {
    releaseDate: '2.4.2019',
    changes: {
      features: [
        'Im Übersichts-Bereich kann jetzt eine Excel oder CSV Datei für die angezeigten Daten exportiert werden.',
      ],
    },
  },
  '2.12.3': {
    releaseDate: '4.4.2019',
    changes: {
      bugs: [
        'Ein Bug wurde behoben bei dem Nutzer mit der notwendigen Berechtigung keine Adressdaten im Sendungen Bereich sehen konnten.',
      ],
    },
  },
  '2.13.0': {
    releaseDate: '10.4.2019',
    changes: {
      features: [
        'Mitnahmen/Abholungen von privat sowie Zustellungen an und Abholungen von Paketshops werden aus Tour-Sicht berücksichtigt.',
      ],
      bugs: ['Inkonsistenzen bei der verwendeten Zeitzone wurden beseitigt.'],
    },
  },
  '2.14.0': {
    releaseDate: '23.5.2019',
    changes: {
      features: [
        'Die Auswahl des Segmentes wird jetzt übergreifend über alle Bereiche gespeichert.',
        'Der Wertebereich der Legende im Übersichtsbereich wurde optimiert.',
        'Der Wert Zustellstrecke pro Tour wurde hinzugefügt.',
      ],
      bugs: [
        'Legendeneinträge zu denen es in der aktuellen Ansicht keinen Wert gibt werden nun nicht mehr angezeigt.',
        'Ein Problem bei dem die Detailkarte im Tourenbereich schwer zu bedienen war wurde behoben.',
        'Zahlreiche kleinere Anzeigefehler wurden behoben.',
      ],
    },
  },
  '2.15.0': {
    releaseDate: '29.7.2019',
    changes: {
      features: [
        'Die Kennzahl "Netto-Zustellzeit" wurde hinzugefügt. Diese beinhaltet im Gegensatz zur "Zustellzeit" keine Pausen der Fahrer.',
        `Die Kennzahl "Produktivität" wurde hinzugefügt. Diese enthält im Gegensatz zur Kennzahl "Sendungen pro Stunde" nun auch Abholungen
        und Mitnahmen auf Tour sowie Paketshop-Interaktionen. Zudem wird die Netto-Zustellzeit als Grundlage verwendet.`,
      ],
    },
  },
  '2.16.0': {
    releaseDate: '12.8.2019',
    changes: {
      features: ['Der Tour-Detail Bereich wurde überarbeitet.'],
    },
  },
  '2.17.0': {
    releaseDate: '23.8.2019',
    changes: {
      features: [
        'UI - Optimierung im Dashboardbereich',
        'Im Dashboardbereich gibt es eine Seitennavigation.',
        'Verbesserung des Tooltips in allen Diagrammen des Dashboardbereiches.',
      ],
    },
  },
  '2.18.0': {
    releaseDate: '10.9.2019',
    changes: {
      features: [
        'Neue Unterseite "Touren" im Dashboardbereich',
        'Erweiterung der Unterseite "Produktivität" um eine Tourtabelle',
        'Der Dashboardbereich basiert auf tagesaktuellen Daten',
        'UI - Optimierungen im Dashboardbereich',
      ],
    },
  },
  '2.19.0': {
    releaseDate: '17.9.2019',
    changes: {
      features: [
        'Kennzahlendefinitionen des Dashboardbereiches sind über eine Infoseite einsehbar',
        'Anzeige des Tourfortschritts auf der Unterseite "Touren" (bei Zeitauswahl "heute"',
        'Auswahlmöglichkeit "letzter Monat" im Dashboardbereich',
        'UI - Optimierungen im Dashboardbereich',
      ],
    },
  },
  '2.20.0': {
    releaseDate: '02.10.2019',
    changes: {
      features: [
        'Benutzerdefinierte Zeitauswahl im Dashboardbereich',
        'UI - Optimierungen im Dashboardbereich',
        'In der Tourenansicht des Sendungsbereichs ist jetzt eine Satellitenansicht verfügbar',
      ],
      bugs: [
        'Ein Fehler bei der Sortierung in den Tabellen wurde behoben\n',
        'Wenn für einen gewählten Zeitraum keine Daten vorliegen, wird die Ansicht entsprechend aktualisiert.\n',
      ],
    },
  },
  '2.21.0': {
    releaseDate: '10.10.2019',
    changes: {
      features: ['Umstellung auf 2-Faktor-Anmeldung'],
    },
  },
  '2.21.1': {
    releaseDate: '14.10.2019',
    changes: {
      bugs: ['Kleine Fehler in der Darstellung wurden behoben.'],
    },
  },
  '2.21.2': {
    releaseDate: '22.10.2019',
    changes: {
      bugs: [
        'Problem beim Neuladen der Produktiviätssicht des Dashboards wurde behoben, die zu einer leeren Seite geführt hat',
        'Kleine Verbesserungen in der Darstellung',
      ],
    },
  },
  '2.21.3': {
    releaseDate: '23.10.2019',
    changes: {
      bugs: ['Kleine Fehler in der Darstellung wurden behoben.'],
    },
  },
  '2.22.0': {
    releaseDate: '08.11.2019',
    changes: {
      features: ['Unternehmer mit mehreren Standorten können diese nun über einen Account einsehen.'],
    },
  },
  '2.22.1': {
    releaseDate: '08.11.2019',
    changes: {
      bugs: ['Ein Problem, das zum erneuten Laden der Seite führte, wurde behoben.'],
    },
  },
  '2.23.0': {
    releaseDate: '13.11.2019',
    changes: {
      features: ['ProPS-Abholungen werden nun getrennt ausgewiessen.'],
    },
  },
  '2.24.0': {
    releaseDate: '01.12.2019',
    changes: {
      features: [
        'Verbesserung Leseszeichen-Funktionlität.',
        'Optimierung der Hintergrundprozesse für die reibungslose Anmeldung.',
      ],
    },
  },
  '2.24.1': {
    releaseDate: '09.12.2019',
    changes: {
      bugs: ['Ein Fehler bei der Zeitauswahl wurde behoben.'],
    },
  },
  '2.25.0': {
    releaseDate: '06.01.2020',
    changes: {
      features: [
        'Die Entwicklung einzelner Kennzahlen einer Tour werden in einem Diagramm dargestellt ' +
          '(Button "Zeitverlauf anzeigen" in der Kartenansicht einer Tour verwenden).',
      ],
      bugs: ['Kleine Fehler in der Darstellung wurden behoben.'],
    },
  },
  '2.25.1': {
    releaseDate: '08.01.2020',
    changes: {
      features: ['Bei jedem Update informiert ab jetzt ein Pop-Up-Fenster über neue Funktionen und behobene Fehler.'],
    },
  },
  '2.25.2': {
    releaseDate: '14.01.2020',
    changes: {
      features: [
        'In der Kartenansicht einer Tour wird die Adresse des Stopps angezeigt, wenn der Mauszeiger diesen berührt.',
      ],
      bugs: ['Ein Fehler bei der Anzeige von Abholstopps in der Kartenansicht wurde behoben.'],
    },
  },
  '2.25.3': {
    releaseDate: '22.01.2020',
    changes: {
      features: [
        'In den Stopp-Details werden mehr Informationen zu den einzelnen Sendungen angezeigt ' +
          '(Sendungsart, Auftraggeber, sowie Eilservice und Identservice, falls vorhanden).\n',
        'Es gibt eine neue Möglichkeit von den Stopp-Details zurück zur Tour zu kommen: ' +
          'Klick auf die Tournummer oben links in den Stopp-Details.',
      ],
    },
  },
  '2.25.4': {
    releaseDate: '03.02.2020',
    changes: {
      features: [
        'In der Tourliste Allgemein steht neben der Brutto-Zustellzeit auch die Netto-Zustellzeit ' +
          '(Hinweis dazu: Sind beide Zeiten gleich, macht ein Fahrer vermutlich keine Pausen. ' +
          'Da es sich jedoch bei den Kennzahlen um keine richtige Arbeits- und Pausenzeiterfassung handelt, ' +
          'liefern diese Zahlen immer nur Indizien für die echten Arbeitszeiten.)',
      ],
      bugs: ['Kleine Fehler in der Darstellung wurden behoben.'],
    },
  },
  '2.26.0': {
    releaseDate: '16.02.2020',
    changes: {
      features: [
        'Die Kartenansicht ist für die Anzeige von GPS-Positionen vorbereitet,' +
          ' wodurch sich die Farbgebung der Stopp-Marker geändert hat (die echten GPS-Position werden zeitnah freigeschaltet).',
        'Die Legende der Kartenansicht ist jetzt ausklappbar.',
        'Kleinere Darstellungsoptimierungen wurden durchgeführt.',
      ],
    },
  },
  '2.26.1': {
    releaseDate: '18.02.2020',
    changes: {
      features: [
        'Anzeige von GPS-Positionen in der Kartenansicht: Wenn durch den Scanner eine gute GPS-Position des Stopps gemessen wurde, wird diese ' +
          'Position in einem in kräftigem Farbton angezeigt. Falls diese nicht vorhanden ist, wird weiterhin die Position der Adresse in einer ' +
          'Pastellfarbe angezeigt.',
        'Anzeige von GPS-Positionen und Adresse in den Stoppdetails: Wenn vorhanden, wird im Stopp die GPS-Position des Scanners und die ' +
          'Position der Adresse angezeigt.',
        'Ein neues aktuelles Schulungs-PDF ist verfügbar. Dieses öffnet sich beim Anklicken direkt in einem neuen Fenster und muss nicht mehr ' +
          'heruntergeladen werden.',
        'Hinweise zu Fehlern in unseren Daten werden zukünftig direkt bei uns im Tool angezeigt. Die HLG-Infoseite werden wir zu diesem Zweck ' +
          'nicht mehr nutzen.',
      ],
    },
  },
  '2.26.2': {
    releaseDate: '27.02.2020',
    changes: {
      features: [
        'Die Hinweise zu Fehlern im Tool werden nicht mehr bei jedem Neustart des Tools angezeigt. Man muss die Hinweise oben rechts im Tool ' +
          'selbst öffnen. Gibt es neue Hinweise, so steht die Anzahl der ungelesenen Hinweise im Button für die Hinweise.',
        'Kleinere Darstellungsoptimierungen.',
      ],
      bugs: [
        'Beim Navigieren in der Kartenansicht mithilfe der Schalter für "vorherige/nächste Tour" bzw. ' +
          '"vorheriges/nächstes Datum" wurden teilweise fehlerhafte Daten angezeigt.',
      ],
    },
  },
  '2.27.0': {
    releaseDate: '15.04.2020',
    changes: {
      features: [
        'In der Kartenansicht einer Tour gibt es einen neuen Button „Unterschriften“: Die Fotos der Unterschriften bei kontaktloser Zustellung ' +
          'werden zu jeder Tour in einem digitalen Tourenbericht angezeigt und können bei Bedarf vergrößert und gedreht werden.',
        'Kleinere Darstellungsoptimierungen.',
        'Aktualisierte Schulungsunterlage',
      ],
    },
  },
  '2.27.1': {
    releaseDate: '20.04.2020',
    changes: {
      features: ['Kleinere Darstellungsoptimierungen in der Unterschriftenliste.'],
      bugs: ['Die Suchfunktion in der Unterschriftenliste funktioniert jetzt auch bei Stopps mit mehreren Empfängern.'],
    },
  },
  '2.27.2': {
    releaseDate: '27.04.2020',
    changes: {
      features: [
        'In der Unterschriftenliste gibt es eine neue Spalte "GPS".Wenn in der Spalte ein Symbol vorhanden ist, dann wurde an dem Stopp auch ein GPS - Signal gemessen, das in der Kartenansicht des Stopps ausgewiesen wird.',
        'In der Unterschriftenliste kannst Du durch einen Klick auf die Stoppnummer(erste Spalte) direkt zur Kartenansicht dieses Stopps gelangen.',
        'In der Unterschriftenliste wird das Laden der Unterschriften durch einen "Loading Indicator" angezeigt.',
      ],
      bugs: [
        'Ein Fehler in der Suchfunktion der Unterschriftenliste wurde behoben (in seltenen Fällen sind vorher durch die Suche Bilder "verschwunden").',
      ],
    },
  },
  '2.28.0': {
    releaseDate: '14.05.2020',
    changes: {
      features: [
        'In der Navigationsleiste links gibt es eine neue Unterseite „Kennzahlen“: Ermöglicht die Auswahl verschiedener Kennzahlen, um zwei Kennzahlen in Relation zueinander zu setzen',
        'Implementierung neuer Kennzahlen wie z.B.Digitale Benachrichtigungsquote',
        'Auf der Unterseite Kennzahlen können in einer Tourtabelle bis zu drei weitere Kennzahlen manuell hinzugefügt werden',
        'FAQs sind unter dem neuen Button “Hilfe” in der Navigationsleiste zu finden',
        'Kleinere Darstellungsoptimierungen',
        'Aktualisierte Schulungsunterlage',
      ],
      bugs: ['Einmalvollmachten werden als Vollmachten ausgewiesen und nicht mehr als SEZ.'],
    },
  },
  '2.28.1': {
    releaseDate: '28.05.2020',
    changes: {
      features: [
        'Die Kennzahl "Nutzungsquote Foto-Unterschriften" wurde hinzugefügt. Hiermit zeigen wir den Anteil der durch ein Foto aufgenommenen Unterschriften an allen Unterschriften. Datenverfügbarkeit ab 29.05.2020.',
        'Die Kennzahl ist im Dashboard über die Unterseite „Kennzahlen“ zu finden',
        'Aufsplittung der Vollmachten in Dauer- und Einmalvollmachten in der Stoppliste',
      ],
    },
  },
  '2.29.0': {
    releaseDate: '15.06.2020',
    changes: {
      features: [
        'Die Kennzahl "Wertsendungen auf Tour" wurde auf der Kennzahlenseite hinzugefügt. Hiermit zeigen wir die Anzahl aller auf Tour geladenen Wertsendungen.',
        'Die Stoppinformationen wurden um Servicearten wie z.B. DB - Kurriergepäck erweitert.',
        'Der Rücklaufgrund N wurde aufgesplittet in N1 – N4.',
        'Der Rücklaufgrund Rücklauf ohne Gegenscan wurde implementiert.',
      ],
      bugs: ['Briefkastenzustellungen werden gesondert ausgewiesen und nicht mehr als Dauervollmacht.'],
    },
  },
  '2.29.1': {
    releaseDate: '07.07.2020',
    changes: {
      features: [
        'Rücklauf-Seite: In der Tourtabelle kann eine Spalte hinzugefügt werden, die anzeigt, wie oft eine bestimmte Tour einen speziellen Rücklaufgrund gescannt hat.',
        'Verbesserung der Darstellung, wenn auf einem Stopp mehrere Sendungen für eine Adresse bearbeitet wurden: Am dazugehörigen Marker in der kleinen Karte werden jetzt alle Empfänger der Adresse aufgelistet. ',
      ],
      bugs: [
        'Die Beträge von Nachnahmesendungen in den Sendungsinformationen werden korrekt angezeigt.',
        'Ein Fehler, der zum Absturz der Seite beim Klick auf bestimmte Stopps sorgte, wurde behoben.',
      ],
    },
  },
  '2.30.0': {
    releaseDate: '22.07.2020',
    changes: {
      features: [
        'In der Kartenansicht einer Tour wurden die Reiter links oben umstrukturiert. Alle wesentlichen Kennzahlen sind jetzt auf den ersten Blick zu sehen. Im zweiten Reiter "Zustellart" wird eine Übersicht der abgeschlossenen Stopps angezeigt und es kann nach diesen gefiltert werden.',
        'Vorbereitung für das nächste Delta-Update: Mit dem nächsten Update des Scanners werden auch die Unterschriften auf dem Scanner im GU-Dashboard angezeigt.',
        'Das FAQ ist aktualisiert wurden und jetzt auch direkt von der Update-Historie aus erreichbar.',
      ],
      bugs: [
        'Bei der Nutzungsquote Foto Unterschriften wird bei Touren, die keine Abliefernachweise erfordern (z.B. reine Paketshop-Touren), nicht mehr 0% Nutzung angezeigt. Dies gilt für alle Quoten bei denen der Nenner 0 ist.',
      ],
    },
  },
  '2.31.0': {
    releaseDate: '13.08.2020',
    changes: {
      features: [
        'Die Historie einer Sendung (für Scans ab dem 24.07.2020) ist über ein Suchfeld oben rechts im Dashboard und über ein Info-i am Barcode in den Stoppdetails und der Unterschriftenliste aufrufbar.',
        'Die Unterschriftenliste kann nach Stopp- und Servicearten gefiltert werden.',
        'Die Icons für die Services wurden überarbeitet. Dabei wurden neue Symbole für Nachbarschaftsabgabeverbot und Nachnahme eingeführt.',
        'Die Schulungsunterlage wurde aktualisiert (inkl. Übersicht über die verwendeten Service-Icons).',
      ],
    },
  },
  '2.31.1': {
    releaseDate: '25.08.2020',
    changes: {
      bugs: ['Kleine Fehler in der Darstellung wurden behoben.'],
    },
  },
  '2.32.0': {
    releaseDate: '03.09.2020',
    changes: {
      features: [
        'Es gibt eine neue Unterseite „Beanstandungen“. Auf dieser Seite werden 1:1 die gleichen Daten zu Servicebeanstandungen dargestellt, die bisher ausschließlich im Qualitätscockpit für Generalunternehmer verfügbar waren.',
        'Von einer einzelnen Servicebeanstandung ist direkt die Sendungshistorie der dazugehörigen Sendung verlinkt.',
        'Filterung der Tourliste durch eingrenzen von Tournummerkreisen',
      ],
    },
  },
  '2.32.1': {
    releaseDate: '09.09.2020',
    changes: {
      features: [
        'Nicht-datenschutzkonforme Fotos (z.B. Personen erkennbar) können gemeldet werden. Hierdurch schaffen wir die Möglichkeit, proaktiv die Einhaltung des Schutzes der personenbezogenen Daten der Sendungsempfänger zu verbessern.',
        'Bei den Filtern nach der Stoppart (in der Karte und der Unterschriftenliste) wird bei Mauszeigerberührung jetzt auch die Anzahl der Sendungen auf diesen Stopps angezeigt.',
        'In den Sendungsinformationen sind Detailinformationen ergänzt worden: Datum des Wunschtages, Uhrzeit des Wunschzeitfensters, Nachnahmebetrag und BNK-Nummer.',
        'Die Unterschriftenbilder laden schneller.',
        'Verbindungsabbrüche zum GU-Dashboard-Server werden angezeigt.',
      ],
      bugs: ['Kleine Darstellungsfehler bei den Servicebeanstandungen sind behoben.'],
    },
  },
  '2.32.2': {
    releaseDate: '15.09.2020',
    changes: {
      features: [
        'Die Tourfilter können jetzt auch in den Lesezeichen gespeichert werden.',
        'Überarbeitung der Filterfunktion in der Unterschriftenliste: Es sind jetzt auch die Anzahl an Sendungen der gesamten Tour und die Anzahl der Sendungen bei den Service-Filtern einsehbar.',
      ],
      bugs: [
        'Die Grafiken von Rücklaufquoten und einigen anderen Kennzahlen sind wieder besser lesbar.',
        'Kleinere Behebungen von grafischen Fehlern.',
      ],
    },
  },
  '2.32.3': {
    releaseDate: '30.09.2020',
    changes: {
      features: [
        'Die Filterfunktion in der Unterschriftenliste wurde noch einmal überarbeitet. Es ist jetzt wieder möglich mehrere Stopparten auf einmal auszuwählen.',
        'In der kleinen Karte in den Stoppdetails kann etwas weiter rein gezoomed werden.',
      ],
      bugs: [
        'Der Schalter zum Ausschalten der kleinen Pins in der kleinen Karte in den Stoppdetails ist wieder nutzbar.',
        'Bei einigen nicht vorhandenen Fotos wurde die falsche Begründung für das Fehlen des Fotos angezeigt. Die Anzeige funktioniert wieder korrekt.',
      ],
    },
  },
  '2.32.4': {
    releaseDate: '15.10.2020',
    changes: {
      features: [
        'Der Hilfebereich wurde überarbeitet. Auf das FAQ und die Schulungsunterlagen kann jetzt direkt zugegriffen werden. Zudem wurde insbesondere die Darstellung des FAQ optimiert.',
        'Im GU-Dashboard werden seit dem letzten Delta-Update auch die normalen Unterschriften im Scanner angezeigt. Die Darstellung dieser Unterschriften wurde optimiert.',
      ],
      bugs: [
        'Bei Sendungen mit sehr langer Historie können jetzt alle Scans eingesehen werden.',
        'Bei Mitnahmen auf Tour wird jetzt auch im Filter die Stoppart RET statt, wie bisher, SEZ angezeigt.',
      ],
    },
  },
  '2.33.0': {
    releaseDate: '27.10.2020',
    changes: {
      features: [
        'Der Filter nach bestimmten Touren ist jetzt auch in den Tourtabellen in Produktivität, Rücklauf und Kennzahlen verfügbar.',
        'Bei der Filterung nach bestimmten Touren können jetzt auch Touren ausgeschlossen werden.',
        'Das FAQ wurde überarbeitet und enthält jetzt auch einen Abschnitt zu Servicebeanstandungen.',
        'Technische Umstellung auf TypeScript. Dadurch ist das GU-Dashboard technisch besser aufgestellt und performanter.',
      ],
      bugs: ['Die Anzeige der Sendungsanzahl in der Unterschriftenliste wurde verbessert.'],
    },
  },
  '2.33.1': {
    releaseDate: '27.11.2020',
    changes: {
      features: [
        'Das Einstellen eines beliebigen Zeitraumes wurde einmal komplett überarbeitet ("Benutzerdefiniert..." in der Zeitauswahl). Per Klick auf den Monatsnamen wird automatisch der entsprechende Monat ausgewählt. Gleiches gilt für Kalenderwochen.',
      ],
      bugs: [
        'In der Sendungshistorie werden Scans an Paketshop besser dargestellt.',
        'Die Freitextsuche in der Unterschriftenliste funktioniert wieder uneingeschränkt.',
        'Die Anzeige des Nachnahmebetrages wurde verbessert.',
        'Der Grund für das Fehlen eines Fotos stimmt jetzt in den Stoppdetails und der Unterschriftenliste überein.',
      ],
    },
  },
  '2.33.2': {
    releaseDate: '10.12.2020',
    changes: {
      features: [
        'In der Kartenansicht und der Unterschriftenliste wird genauer zwischen den Vollmachtsarten unterschieden. Neben "DV" für Dauervollmacht und "EV" für Einmalvollmacht, gibt es jetzt auch "BV" für BNK-Vollmacht (Vollmachten aufgrund einer entsprechend ausgefüllten BNK von einem vorherigen Zustellversuch).',
        'In den Sendungsdetails einer BNK-Vollmacht ist die entsprechende Tagesvollmachtnummer einsehbar.',
        'Die Information, ob es sich um eine DV, EV oder BV handelt, ist erst mit Tourabschluss vorhanden. Bis dahin werden die entsprechen Stopps allgemein als "VM" für Vollmacht dargestellt.',
      ],
      bugs: ['Kleine Optimierung in der Filterung der Servicearten in der Unterschriftenliste.'],
    },
  },
  '2.34.0': {
    releaseDate: '18.01.2021',
    changes: {
      features: [
        'Das GU-Dashboard hat eine neue Startseite. Auf der neuen Startseite kann sich jeder Nutzer die wichtigsten Kennzahlen selbst konfigurieren. Zudem können Zielwerte für die Standorte zu den Kennzahlen hinterlegt werden (Details dazu in der aktualisierten Schulungsunterlage im Hilfebereich).',
        'Es gibt neue Kennzahlen zu den Themen Laufzeiten und Bestände, die auf der neuen Startseite und im Kennzahlenbereich auswählbar sind: E+0, E+0 Eil, Liegenbleiber, Liegenbleiber Eil, Liegenbleiber-Quote, Liegenbleiber-Quote Eil, Bestand, Lagernde und AFNUS (Details dazu in der Kennzahlenerklärung im Hilfebereich).',
        'Verbesserte Fehlerbehandlung: Wenn technische Fehler auftreten wird auf diese hingewiesen und die das GU-Dashboard kann mit einem Klick neu gestartet werden.',
      ],
      improvements: [
        'Verschiedene Performanceoptimierungen, durch die das GU-Dashboard schneller lädt.',
        'Vereinheitlichungen von Tabellen und Informationstexten.',
        'Optimierung der benutzerdefinierten Zeitauswahl.',
        'Aktualisierung der Schulungsunterlage.',
      ],
      bugs: ['Korrektur der Kalenderwochen in der benutzerdefinierten Zeitauswahl für 2021.'],
    },
  },
  '2.34.1': {
    releaseDate: '03.02.2021',
    changes: {
      features: ['Die neue Kennzahl Lagernd-Quote, die den Anteil der Lagernden am Bestand anzeigt, ist auswählbar.'],
      improvements: [
        'Performanceoptimierungen, durch die Diagramme und Zahlen schneller laden.',
        'Kleinere Verbesserungen in der Darstellung.',
      ],
      bugs: [
        'Die Hinweise zum Tool oben rechts (z.B. bei fehlenden Daten) werden für alle Benutzer angezeigt.',
        'Die Filter auf Tournummern in den Seiten Produktivität und Rücklauf funktionieren wieder.',
        'Bei der Anzeige des Zeitverlaufs einer Tour werden wieder die richtigen Werte angezeigt.',
      ],
    },
  },
  '2.34.2': {
    releaseDate: '19.02.2021',
    changes: {
      features: [
        'Die neue Kennzahl Zugangsmengen steht bereit.',
        'Die eingesetzte Scanner-Version ist einsehbar (In der Kartenansicht einer Tour, links oberhalb der Stoppliste).',
      ],
      improvements: [
        'Die Bestände und Zugänge werden bereits taggleich berechnet (ab 08:15). Dadurch steht auch die Liegenbleiber-Quote taggleich zur Verfügung (ab 11:45).',
        'Optimierung und Vereinheitlichung der Suchfunktionen im GU-Dashboard (z.B. gesuchte Sendungsbarcodes bleiben nach der Suche im Feld stehen).',
        'An den Adressen in den Stopps und der Unterschriftenliste steht jetzt auch die TEZE',
      ],
      bugs: [
        'Kleinere Fehler in der Darstellung von "Touren im Zeitverlauf" und bei der Auswahl eines benutzerdefinierten Zeitraumes sind behoben.',
        'Keine Standortwechsel bei Wechsel der Ansichten mehr.',
        'Stopp öffnen in der Sendungssuche funktioniert wieder besser.',
        'Das Teilen von Links einer Tour funktioniert wieder.',
      ],
    },
  },
  '2.34.3': {
    releaseDate: '22.02.2021',
    changes: {
      improvements: ['Auf der Startseite können bis zu 15 Kennzahlen ausgewählt werden.'],
      bugs: [
        'Eine fehlerhafte Abfrage, die das System verlangsamt und teilweise zu Problemen mit den Rechten geführt hat, wurde entfernt.',
        'In dem Menüpunkt Touren ist wieder eine "benutzerdefinierte" Zeit wählbar.',
        'Ein Sonderfall, bei dem es zum Absturz der Seite Beanstandungen kam, funktioniert wieder korrekt.',
      ],
    },
  },
  '2.34.4': {
    releaseDate: '26.02.2021',
    changes: {
      improvements: [
        'Optimierung der Sendungssuche (z.B. Leerzeichen führen nicht mehr zu Fehlern, auch 12-stellige Retourenbarcodes sind suchbar).',
        'Bessere Darstellung von Quoten, bei denen noch nicht alle Daten verfügbar sind.',
      ],
      bugs: [
        'Bei Nutzern mit Zugriff auf mehrere Standorte: Bei einem Neuladen bleibt der aktuelle Standort ausgewählt.',
      ],
    },
  },
  '2.35.0': {
    releaseDate: '08.03.2021',
    changes: {
      features: [
        'Die SB-Quote ist jetzt als neue Kennzahl verfügbar und kann im Dashboard hinzugefügt werden. Die Quote ist ab 14:00 für den Vortag verfügbar.',
        'In der Unterschriftenliste gibt es eine neue Ansicht: Dort sind die Abliefernachweise einer Tour auf einem Blick sichtbar (Der Button zum Umschalten der Ansicht befindet sich rechts neben der Freitextsuche).',
      ],
      bugs: [
        '"Stopp öffnen" Button hatte nicht in allen Fällen funktioniert.',
        'Beim Neuladen der Startseite wird sich die Zeitauswahl wieder gemerkt.',
      ],
    },
  },
  '2.35.1': {
    releaseDate: '31.03.2021',
    changes: {
      improvements: [
        'Optimierungen von Diagrammen für bestimmte Kennzahlen (z.B. bei E+0 und E+0 Eil sind die Entwicklungen besser zu sehen).',
        'Optimierung der Darstellungen langer Zeiträume in Diagrammen (z.B. bei "letztes Vierteljahr" werden jetzt die Werte pro Woche angezeigt).',
        'Optimierungen der Infofelder in den Diagrammen.',
        'Aktualisierte Schulungsunterlage.',
      ],
      bugs: [
        'Behebung kleinerer Darstellungsfehler in der Kartenansicht einer Tour nach Verwendung des „Stopp öffnen“-Button aus der Sendungshistorie.',
      ],
    },
  },
  '2.35.2': {
    releaseDate: '15.04.2021',
    changes: {
      features: [
        'Bei einigen Kennzahlen (z.B. Lademenge, Zugangsmengen) können jetzt auch Zielbereiche gesetzt werden. Die bisherigen Zielwerte dieser Kennzahlen mussten dafür gelöscht werden.',
        'Es gibt eine neue Kennzahl: Die vSB-Quote (Anteil der verlustrelevanten Beanstandungen an der Abwicklungsmenge).',
      ],
      improvements: [
        'Für Hermes-Mitarbeiter: Bessere Unterscheidung zwischen dem Depotgebiet und der Zustellfunktion des Depots in der Standortauswahl (Neuer Name und neues Symbol).',
      ],
    },
  },
  '2.36.0': {
    releaseDate: '08.06.2021',
    changes: {
      features: [
        'Im Dashboard werden jetzt auch Vertragspartner an Depots inklusive ihrer Taskforce-Touren angezeigt.',
      ],
      improvements: ['Die Schulungsunterlage wurde um ein Kapitel für Vertragspartner erweitert.'],
      bugs: ['Für einige LC/Depotmitarbeiter funktionierte die Sendungssuche nicht vollständig.'],
    },
  },
  '2.36.1': {
    releaseDate: '14.06.2021',
    changes: {
      features: [
        'Das Menü unten links wurde erweitert. Über "Mein Profil" kann jeder Nutzer Passwort und Email-Adresse selbst ändern. Zudem kann der eigene zweite Faktor zurückgesetzt werden (z.B. bei einem neuem Smartphone).',
        'An Standorten, an denen andere Vertragspartner mit unterstützen, werden die Taskforce-Touren mit einem Symbol in der Tourtabelle hervorgehoben.',
      ],
    },
  },
  '2.36.2': {
    releaseDate: '29.06.2021',
    changes: {
      features: [
        'In der Kartenansicht einer Tour wurde bisher immer eine Mischung aus GPS-Positionen und Adresspositionen dargestellt. Oben rechts in der Kartenansicht kann jetzt auf "ADR." (nur Adressen) oder "GPS" (nur GPS-Positionen) umgestellt werden.',
      ],
      improvements: ['In der Galerieansicht aller Fotos einer Tour kann ein höherer Zoom eingestellt werden.'],
    },
  },
  '2.37.0': {
    releaseDate: '28.07.2021',
    changes: {
      features: [
        'Die Komponentenreihenfolge auf der Startseite kann über das „Rädchen“ oben rechts verändert werden.',
        'Einzelne Komponenten können über das „Rädchen“ oben rechts ein- oder ausgeblendet werden.',
        'Auf der Startseite gibt ist jetzt auch bei ZSB und VP eine Tabelle mit allen Touren zu sehen. Die entsprechenden Kennzahlen können individuell zusammengestellt werden.',
        'Alle Nutzer aus den Areas sehen im Dashboard Standorte auch dann, wenn sie laut H@V weniger als 6 Monate ungültig sind.',
      ],
      improvements: [
        'Alle genannten „neue Funktionen“ und auch die Zeitauswahlen werden dauerhaft (auch nach Abmeldung oder dem Schließen des Browsers) für euch persönlich gespeichert.',
      ],
      bugs: [
        'Wenn eine Sendung auf mehreren Stopps einer Tour ist, dann öffnet der "Stopp öffnen"-Button in der Sendungshistorie den richtigen Stopp.',
      ],
    },
  },
  '3.0.0': {
    releaseDate: '25.08.2021',
    changes: {
      features: [
        'Die operativen Tourkennzahlen zu Lademengen, Zustellungen und Rückläufer werden jetzt nach einer neuen Logik berechnet. Es wird nicht mehr jeder Scan gezählt, sondern berücksichtigt, wenn eine Sendung mehrfach gescannt wird.',
        'Diese neue Berechnung gilt rückwirkend ab dem 16.08.2021. Die Historie davor ist noch nach alter Logik.',
        'Mit dieser Umstellung sind auch neue Kennzahlen im Dashboard verfügbar. Zum einen die Dauervollmachtsquote zum anderen Kennzahlen zum gezielten auswerten von Abholaufträgen (Abholaufträge, Erfolgreiche Abholaufträge, Rücklauf Abholkarten nach Grund).',
      ],
      improvements: ['Die Definitionen der Kennzahlen wurden entsprechend der neuen Logiken angepasst.'],
    },
  },
  '3.1.0': {
    releaseDate: '11.10.2021',
    changes: {
      improvements: [
        'Komplette technische Weiterentwicklung der Webseite (keine neue Funktion für den Nutzer aber wichtige Voraussetzung für die Entwicklung weiterer Funktionen)',
      ],
      bugs: [
        'Der Schieberegler in der Galerieansicht der Foto-Unterschriften funktioniert wieder.',
        'Die benutzerdefinierte Zeitauswahl in der Tabelle der einzelnen Servicebeanstandungen funktioniert wieder.',
      ],
    },
  },
  '3.2.0': {
    releaseDate: '19.10.2021',
    changes: {
      features: [
        'Es gibt jetzt auch die Möglichkeit den Rücklauf von Eilsendungen genauer zu untersuchen. Zum einen gibt es jetzt auch Kennzahlen für die verschiedenen Rücklaufgründe von Eilsendungen, zum anderen kann in dem Diagramm mit allen Rücklaufgründen auf der Rücklaufseite jetzt auch auf Eilsendungen gefiltert werden.',
      ],
      improvements: [
        'Die Namen der Rücklaufgründe in Tabellen und auf der Startseite wurden abgekürzt. Ziel: Übersichtliche, kurze Namen in den Tabellen und eine einfachere Unterscheidung zwischen dem Rücklaufern von allen Sendungen, Eilsendungen und Abholaufträgen.',
      ],
      bugs: [
        'Wenn eine Tour aus einer Tabelle angeklickt wird, wird wieder immer die aktuellste Tour des Zeitraumes angezeigt.',
      ],
    },
  },
  '3.2.1': {
    releaseDate: '04.11.2021',
    changes: {
      improvements: [
        'Die Stoppmarker in der Karte einer Tour ändern sich jetzt mit dem Zoom der Anzeige. Bei hohem Zoom ist die Stoppnummern sichtbar, bei geringem Zoom nicht.',
      ],
      bugs: [
        'Wenn eine Tour nur die Scanner-Unterschriften macht, dann steht bei der Foto-Quote 0% statt "-".',
        'In der Servicebeanstandungstabelle kann das Suchfeld und der Haken bei "Verlustrelevant" wieder kombiniert verwendet werden.',
      ],
    },
  },
  '3.3.0': {
    releaseDate: '18.11.2021',
    changes: {
      features: [
        'Es gibt eine neue Kennzahl „Zustellungen nach Art“ in der Kategorie „Zustellvorgänge“ in der Auswahl der Kennzahlen. Damit kann ausgewertet werden, ob Sendungen beim Originalempfänger, Nachbarn, per Einmalvollmacht, etc. zugestellt wurden. Hinweis: Die genaue Art der Vollmacht kennen wir erst nach Tourabschluss. Bis dahin wird es allgemein als „Vollmacht“ dargestellt und nach Tourabschluss aktualisiert.',
        'Auf der Dashboard-Startseite können jetzt auch gestapelte Diagramme angezeigt werden – bis dato war es nur möglich sich die einzelnen Gründe anzuzeigen. Über die Trend-Komponente auf der Dashboard-Startseite kann so z.B. die Kennzahl „Rückläufer nach Grund“ ausgewählt werden und es erscheint das gleiche Diagramm wie auf der Rücklaufseite. Die Kennzahlen „Zustellungen nach Art“, „Rücklauf Abholkarten nach Grund“, „Rückläufer nach Grund" und „Benachrichtigungen nach Art“ können auf diese Weise direkt auf der Startseite visualisiert werden.',
      ],
    },
  },
  '3.3.1': {
    releaseDate: '09.12.2021',
    changes: {
      features: [
        'Scanunterbrechungen größer als 20 Minuten werden ab sofort in der Stoppliste gekennzeichnet. Sofern zwei aufeinanderfolgende Stopps mehr als 20 Minuten auseinanderliegen, wird die Dauer der Unterbrechung ab sofort angezeigt.',
      ],
    },
  },
  '3.3.2': {
    releaseDate: '20.01.2022',
    changes: {
      improvements: ['Optimierung der Komponentenanpassung bei Größenveränderung'],
    },
  },
  '3.3.4': {
    releaseDate: '01.03.2022',
    changes: {
      features: [
        'Neue Quote ZSB+0 Standard und ZSB+0 Eil: Anteil an Warensendungen ohne Terminservice/mit Eilservice für die taggleich zu ihrem ersten Zustellversuch auch der Sendungseingang an der ZSB gescannt wurde (weitere Infos unter Informationen->Kennzahlen).',
      ],
    },
  },
  '3.4.0': {
    releaseDate: '15.03.2022',
    changes: {
      features: [
        'Optimierung des Tabellenaufbaus der Servicebeanstandungen. Nutzerfreundlicher Filter je Spalte: Tour, Sendungs-ID, SB-Grund und Auftraggeber. Eine Mehrfachauswahl an SB-Gründen und Auftraggebern ist nun möglich.',
      ],
    },
  },
  '3.4.1': {
    releaseDate: '04.04.2022',
    changes: {
      features: ['Die Kennzahl „Abwicklungsmenge (ZSB/VP+)“ wurde der Kategorie <i>Leistung</i> hinzugefügt.'],
      improvements: [
        'Scanunterbrechungen werden nun nicht mehr auf gefilterte Listen dargestellt (Auswahl einer Zustellart). Die Unterbrechungen werden wie gewohnt angezeigt, wenn die Stopps aufeinanderfolgend sind und mehr als 20 Minuten auseinanderliegen.',
        'Optimalere Darstellung der Scanunterbrechung: Scanunterbrechungen werden nun in Stunden und Minuten dargestellt. Liegt ein Tag zwischen den Bearbeitungen den Sendungen der Tour, wird „Folgetag“ als Scanunterbrechung angezeigt.',
      ],
      info: ['Die Lesezeichenfunktion wurde aufgrund einer veralteten Version bis auf weiteres entfernt.'],
    },
  },
  '3.4.2': {
    releaseDate: '07.04.2022',
    changes: {
      bugs: [
        'Alle Servicebeanstandungen werden nun wieder vollständig in der Komponente "Touren mit den meisten Beanstandungen" dargestellt.',
      ],
    },
  },
  '3.4.3': {
    releaseDate: '21.04.2022',
    changes: {
      improvements: [
        'In einer Tabelle werden die gesetzten Filter je Session gespeichert, auch wenn man die eine andere Seite innerhalb des Tools aufruft. Eine Session bedeutet, dass sich nicht erneut angemeldet wurde während der Nutzung.',
      ],
    },
  },
  '3.5.0': {
    releaseDate: '26.04.2022',
    changes: {
      features: [
        'Es gibt einen neuen Menüpunkt "Hinweise" in dem aktiv auf "unplausibles Verhalten" von Zustellern aufmerksam gemacht wird. Dies soll dir Zeit bei der Qualitätsprüfung und -verbesserung sparen. Es deckt jedoch nicht alle Auffälligkeiten ab und wird zukünftig noch erweitert. Es werden Hinweise zu folgenden unplausiblen Ereignissen gegeben: 1) Wunschzeitfenster nicht eingehalten (Verstoß WZF) 2) Verstoß Auftraggeber-Abholzeitfenster (Verstoß ATG-AZF) 3) Tagversetzte Bearbeitung 4) Rücklauf A am Wunschtag 5) Verstoß Hermes Zustellzeit',
      ],
      info: [
        'Der Menüpunkt "Kennzahlen" unten links bei Informationen heißt nun "Definitionen". Hier findest du Informationen zu den Kennzahlen und den neuen Hinweisen.',
      ],
    },
  },
  '3.5.1': {
    releaseDate: '24.05.2022',
    changes: {
      bugs: [
        'In der kleinen Karte in den Stopps funktionieren die Hover mit den Adressen und genauen GPS-Positionen wieder korrekt.',
      ],
    },
  },
  '3.5.2': {
    releaseDate: '13.06.2022',
    changes: {
      improvements: [
        'Im Menüpunkt "Prognose" stehen nun Prognosewerte für 8 Wochen zur Verfügung. Eine Aktualisierung der Prognose findet wie gewohnt im Vier-Wochen-Zyklus statt. Bei Fragen zur Prognose wende dich bitte an MP-Coreteam@hermesworld.com.',
      ],
    },
  },
  '3.5.3': {
    releaseDate: '29.06.2022',
    changes: {
      info: ['Umbenennung des Reiters "Stopp Art" in "Zustellart"'],
      improvements: [
        'Wording Anpassung in der Hinweistabelle: „Verstoß Hermes Zustellzeit“ in „Außerhalb Hermes Zustellzeit“',
        'Wording Anpassung in der Hinweistabelle: „Verstoß Wunschzeitfenster“ in „Außerhalb Wunschzeitfenster“',
        'Wording Anpassung in der Hinweistabelle: „Verstoß ATG-Abholzeitfenster“ in „Außerhalb ATG-Abholzeitfenster“',
        'Wording Anpassung in der Hinweistabelle: „Verstoß / Verstöße“ in „Sendung / Sendungen“',
        'Bei Klick auf die Zustellart in einer Tour, werden in der Karte nicht alle weiteren Stopps ausgeblendet. Filterfunktion bleibt bestehen, sodass die Liste sich anpasst, aber auf der Karte ändert sich nichts.',
      ],
      features: [
        'Stopps mit mehreren Sendungen und verschiedenen Zustellarten sind nun auch anhand des Icons in der Stoppliste erkennbar. Liegt beispielsweise ein Stopp mit unterschiedlichen Rücklauf Scannungen vor (N1, N2, N3), wird dies über das neutrale Icon „N“ angezeigt. Um zu sehen, welche Zustellarten sich dahinter verbergen, kann man mit der Mouse auf das Icon gehen und die entsprechenden Zustellarten werden im Hover angezeigt.',
        'Gibt es pro Stopp sowohl Sendungen als auch Rückläufer, wird das Icon in den Farben Grün und Rot eingefärbt.',
        'Um auf eine bestimmte Zustellart zu filtern, klickt man auf das Zustellart Icon. Wenn es sich um einen Stopp mit unterschiedlichen Zustellarten handelt, wird der Stopp angezeigt worin sich eine Sendung mit entsprechender Zustellart befindet.',
        'In der Unterschriftenliste werden alle Stopps nun mit korrekter Zustellart je Sendung angezeigt.',
        'Die Zahl unter der Zustellart, zeigt die Anzahl der Sendungen der entsprechenden Zustellart an. Über ein Hoverfeld sieht man zusätzlich die Anzahl der entsprechenden Stopps.',
      ],
    },
  },
  '3.5.4': {
    releaseDate: '06.07.2022',
    changes: {
      features: [
        'Auf der Dashboard-Startseite kannst Du Dir die folgenden neuen KPIs unter „Tourlänge & -Dauer“ hinzufügen: Anfahrtstrecke, Anfahrtszeit, Rückfahrtstrecke, Rückfahrtzeit, Tourfahrtzeit, Tourstrecke.',
        'Dabei zu beachten, dass dies keine echten Messungen, sondern Berechnungen auf Basis der Stopps der Touren sind. In der Praxis kann es bei den angezeigten Strecken und Fahrzeiten (z.B. aufgrund aktueller Verkehrssituationen) größere Abweichungen geben.',
        'Zusätzlich findest Du die Tourkilometer einer Tour direkt oben links in den Kennzahlen einer Tour.',
        'Weitere Informationen zur Berechnung dieser Kennzahlen findest Du im Hilfemenü unten links unter Definitionen.',
      ],
    },
  },
  '3.5.5': {
    releaseDate: '03.08.2022',
    changes: {
      features: [
        'In der Karte ist die Richtung der Stoppreihenfolge nun mit Pfeilen zu erkennen.',
        'Neue Kennzahl „Bulky auf Tour“. Anzahl aller auf Tour geladenen Bulkys. Mehr Informationen dazu unter „Definitionen“.',
        'Neue Kennzahl „Bulky zugestellt“. Anzahl aller auf Tour geladenen und erfolgreich zugestellten Bulkys. Mehr Informationen dazu unter „Definitionen“.',
      ],
      bugs: ['Die Farbe der Filterung nach Z und oGS wurde angepasst.'],
    },
  },
  '3.5.6': {
    releaseDate: '08.08.2022',
    changes: {
      improvements: [
        'Die Kennzahlen zu Zeitdauern, wie zum Beispiel Netto-Zustellzeit oder Anfahrtszeit, werden in einem besser lesbaren Format dargestellt. Damit sind die Zeiten jetzt auf eine Minute genau ablesbar.',
      ],
      bugs: [
        'Bei dem Filter Button des Sendungstyps „Zeitmangel – Z“ wurde der Name nicht angezeigt. Auch die Anzahl der Sendungen und Stopps wurde korrigiert und wird nun korrekt dargestellt.',
      ],
      info: [
        'Um komprimierter relevante Informationen im GU-Dashboard bereitzustellen und doppelte Ansichten zu vermeiden, wird der Bereich Produktivität ab sofort ausgeblendet. Alle Informationen, die dort zu finden waren, können über die noch bestehenden Tabellen und Diagramme (Trend) abgebildet werden. Die Informationen sind alle noch vorhanden und über das Dashboard einsehbar.',
      ],
    },
  },
  '3.5.7': {
    releaseDate: '15.08.2022',
    changes: {
      improvements: ['Designanpassungen der Ansicht von noch offenen Stopps.'],
      bugs: [
        'Geht man mit der Maus über die Stoppliste, werden nun wieder die entsprechenden Stopps in der Karte hervorgehoben.',
      ],
    },
  },
  '3.5.8': {
    releaseDate: '18.08.2022',
    changes: {
      features: [
        'Neuer Hinweis zu unplausiblen Verhalten „Teillieferung (mehrcollig)“. Alle Sendungen, die zusammen zugestellt werden sollen (mehrcollig), jedoch nur eine Teillieferung zugestellt wurde führen zu einem Hinweis.',
        'Grafische Anpassung des Icons einer Mehrcolligen Sendung.',
      ],
    },
  },
  '3.5.9': {
    releaseDate: '30.08.2022',
    changes: {
      improvements: [
        'Die Kennzahlen E+0 Standard und E+0 Eil werden jetzt auch für Vertragspartner ausgewiesen (Ausnahme Vertragspartner an HZB, die in HADV eine ZSB sind).',
        'Die am 15.08.2022 eingeführten Servicebeanstandungsgründe "fehlerhafte N-Scannung" und "fehlerhafte A-Scannung" sind analog zu den bestehenden Servicebeanstandungsgründen vollständig auswertbar.',
      ],
    },
  },
  '3.5.10': {
    releaseDate: '31.08.2022',
    changes: {
      features: [
        'Neuer Hinweis zu unplausiblen Verhalten „RL am Tourende“. Hat eine Tour auffällig viele Rückläufer am Tourende (Ausnahme: Zeitmangel), so wird dies automatisch als Hinweis erkannt. Die genaue Definition dazu, findet ihr unter "Definitionen" im GU-Dashboard.',
      ],
    },
  },
  '3.5.11': {
    releaseDate: '07.09.2022',
    changes: {
      bugs: [
        'Sofern ein Stopp keine Geo Daten enthält und nicht in der Karte angezeigt werden kann, kann dennoch über den Navigationsbutton in der Karte  von Stopp zu Stopp navigiert werden.',
      ],
      features: ['In der Sendungshistorie wird nun auch der AV-Status angezeigt.'],
    },
  },
  '3.6.0': {
    releaseDate: '14.09.2022',
    changes: {
      features: [
        'Es gibt neue Produktivitätskennzahlen, die die Prozesse an der Haustür, beim Paketshop und beim Auftraggeber getrennt berechnen: „HT-Produktivität“, „PS-Produktivität“ und „ATG-Produktivität“. Dies hat den Vorteil, dass zum Beispiel Mischtouren besser mit reinen Haustür-Touren verglichen werden können.',
        'Für die Berechnung der getrennten Produktivitäten wurde die Kennzahl „Netto-Zustellzeit“ überarbeitet und auf die drei Systeme aufgeteilt. Demensprechend gibt es jetzt zusätzlich die Kennzahlen „Netto-HT-Zeit“, „Netto-PS-Zeit“ und „Netto-ATG-Zeit“, die in Summe wieder die „Netto-Zustellzeit“ ergeben.',
        'Die bisherigen Kennzahlen zu Produktivität und Netto-Zustellzeit sind vorübergehend (voraussichtlich bis Januar) weiter verfügbar: „Produktivität alt“ und „Netto-Zustellzeit alt“.',
        'An allen Stellen, wo bisher die allgemeine Produktivität voreingestellt war, befindet sich jetzt die „HT-Produktivität“. Zudem gibt es im Bereich „Touren“ für jede der 3 Produktivitäten einen separaten Reiter.',
        'Die neuen Kennzahlen sind rückwirkend ab dem 01.08.2022 verfügbar und können, wie gewohnt, auf der Dashboard-Startseite und der Kennzahlen-Seite über die Kennzahl-Auswahl ausgewählt werden. Dabei findet ihr die Produktivitätskennzahlen in der Kategorie „Leistung“ und die neuen Kennzahlen zu den Zeiten in der Kategorie „Tourlänge & -Dauer“.',
      ],
    },
  },
  '3.7.0': {
    releaseDate: '21.11.2022',
    changes: {
      features: [
        `Es gibt nun die S&V-Quote, die unter "Kennzahl hinzufügen" unter "Schäden & Verluste" zu finden ist. Die S&V-Quote ist der Quotient aus der Anzahl der Regresse und der Abwicklungsmenge (Letzte Meile). Die Quote steht zur Zeit nur für ZSB und VP+ zur Verfügung, nicht für Depots. Weitere Details sind unter "Definitionen" zu finden.`,
        `Vereinheitlichung der Tabellen mit neuen Features/Optimierungen.`,
        `Optimierung der Tabellen: die Spalten der Tabellen können per Drag & Drop nun ganz einfach verschoben und beliebig angeordnet werden. Dies kann auch über die Tabellen-Konfiguration über das Rädchen direkt an der Tabelle vorgenommen werden.`,
        `Optimierung der Tabellen: das Hinzufügen weiterer Kennzahlen kann über das Rädchen direkt an der Tabelle vorgenommen werden „Tabelle konfigurieren“.`,
        `Optimierung der Tabellen: beliebig viele Spalten können nun hinzugefügt werden. Eine Scrollbar ermöglicht das Verschieben der Spalten, um die komplette Tabelle betrachten zu können.`,
        `Exportfunktion der Tabellen direkt über das Export-Icon an der Tabelle möglich. Das Dateiformat ist eine Excel.`,
      ],
    },
  },
  '3.7.1': {
    releaseDate: '24.11.2022',
    changes: {
      bugs: [
        `Der Bearbeitungsfortschritt einer Tour wird in Form eines Balkens wieder dargestellt. Zu sehen ist der Fortschritt in allen Tabellen, wo die Kennzahl „Touren“ auszuwählen ist. Mit Auswahl des Zeitraums „Heute“ wird dann der aktuelle Stand der Bearbeitung angezeigt.`,
        `Der Bearbeitungsfortschritt einer Tour wird nun auch prozentual dargestellt. Sofern die Maus über den Fortschrittsbalken geführt wird.`,
        `Tabellen Export der SB-Tabelle funktioniert, auch wenn keine Tournummer vorliegt.`,
        `Tabellen Export der SB-Tabelle enthält alle Tournummern, sofern diese Information vorliegt.`,
        `In dem Excel-Export der SB-Tabelle wird nun der Meldezeitpunkt analog der Tabellenansicht dargestellt.`,
      ],
    },
  },
  '3.7.2': {
    releaseDate: '24.11.2022',
    changes: {
      bugs: [
        `VP und TF Touren werden wieder entsprechend an der Tourennummer gekennzeichnet.`,
        `Die Spalte „Standorte“ wird in den Tabellen auf dem „Dashboard“ und unter „Touren“ angezeigt. Dies gilt, wie gewohnt, nur bei den VPs.`,
      ],
    },
  },
  '3.7.3': {
    releaseDate: '07.12.2022',
    changes: {
      improvements: ['Optimierung der Berechnung von den Abwicklungsmengen (ZSB/VP+).'],
    },
  },
  '3.8.0': {
    releaseDate: '19.01.2023',
    changes: {
      improvements: [
        `Die Zeitauswahl zeigt nach Auswahl von "gestern" nun in der Detailansicht das aktuelle Datum an, wodurch die Dokumentation erleichtert wird.`,
        'Optimierte Tooltipanzeige bei den Tabellen.',
        'Graphische Optimierung der Kennzahlenauswahl und Tabellen Konfiguration.',
      ],
      bugs: [
        'Die Anordnung der Tabellenspalten wird pro Sitzung gespeichert.',
        'In manchen Fällen wurde bei Sendungen mit Briefkastenzustellung und Vollmachten die falsche Zustellart angezeigt. Die Zustellarten werden nun wieder korrekt angezeigt.',
        'Gelegentlich auftretende Scrollingprobleme bei der Kennzahlauswahl wurden behoben.',
        'Ein Fehler in der Tabellendarstellung, welcher leere Tabellenspalten bei vorhandenen Daten zur Folge hatte, wurde behoben.',
        'VPs können nun wieder Zielwerte auf der Kennzahlenübersicht im Dashboard setzen.',
        'Korrektur fehlerhafter Differenzwerte.',
      ],
      features: [
        'Die Tourentabelle wurde überarbeitet und in der Funktionalität an die anderen Tabellen angepasst.',
        'Die veralteten Kennzahlen Produktivität alt und Netto-Zustellzeit alt wurden aus LMA ausgebaut. Die neuen Kennzahlen stehen selbstverständlich weiterhin zur Verfügung (HT-Produktivität, ATG-Produktivität und PS-Produktivität und alle relevanten Netto-Zustellzeiten).',
      ],
    },
  },
  '3.8.1': {
    releaseDate: '20.01.2023',
    changes: {
      bugs: [
        'Wenn ein Stopp mehrere Sendungen beinhaltet hat und eine der Sendungen mit einem gewissen Service wie Eilsendung gekennzeichnet war, wurden alle Sendungen dieses Stopps als Eilservice ausgewiesen. Dies wurde nun behoben, sodass nur die wirklich betroffenen Sendungen den Services zugeordnet werden.',
      ],
    },
  },
  '3.8.2': {
    releaseDate: '23.01.2023',
    changes: {
      improvements: ['Performance Optimierung der Touren Tabelle. Die Kennzahlen werden schneller angezeigt.'],
      bugs: [
        'Die absolute Veränderung einer Kennzahl wird nun je nach Verbesserung (grün) oder Verschlechterung (rot) in der richtigen Farbe dargestellt.',
      ],
    },
  },
  '3.8.3': {
    releaseDate: '24.01.2023',
    changes: {
      bugs: ['Die Tourentabelle der VP Standorte sind wieder in LMA verfügbar.'],
    },
  },
  '3.8.4': {
    releaseDate: '08.02.2023',
    changes: {
      bugs: [
        'Wenn man sich in einer Tour befindet, führt ein Klick auf den Zurück-Button der Browsernavigation nicht mehr zu einem undefinierten Zustand in welchem keine Organisationseinheit ausgewählt ist.',
        `Es ist jetzt wieder möglich die aktuelle Ansicht durch "kopieren" der Browserzeile zu teilen.`,
        'Ein Fehler in der Filterlogik, der unplausible Eingaben ignorierte, wurde behoben.',
      ],
    },
  },
  '3.8.5': {
    releaseDate: '14.02.2023',
    changes: {
      improvements: [
        'Die eingestellte Reihenfolge der Spalten in den Tabellen wird nun je Session übergreifend gespeichert.',
      ],
      bugs: ['Die Summen und Vergleichswerte in den Tabellen passen sich entsprechend der gesetzten Filter wieder an.'],
    },
  },
  '3.8.6': {
    releaseDate: '15.02.2023',
    changes: {
      improvements: [
        'Optimierung der Berechnung der Brutto-Zustellzeit. Durch die bisherige Berechnung kam es selten dazu, dass die Brutto-Zustellzeit geringer als die Netto-Zustellzeiten war. Dies wurde nun verbessert.',
      ],
    },
  },
  '3.8.7': {
    releaseDate: '20.02.2023',
    changes: {
      improvements: [
        'Alle Tabellen können nun als Fullscreen angezeigt werden, sodass sich die Tabelle über den gesamten Bildschirm öffnet. Mit Klick auf das Icon direkt oben rechts an der Tabelle öffnet sich die Tabelle im Vollbildmodus.',
      ],
      bugs: [
        'In den Diagrammen springt die Anzeige je nach Zeitspanne auf KW bzw. Monate korrekt um.',
        'Beim Export von Tabellen werden wieder alle Seiten der Tabelle exportiert.',
      ],
    },
  },
  '3.8.8': {
    releaseDate: '28.02.2023',
    changes: {
      bugs: ['Die Unterbrechungen von genau 60 Minuten werden in der Tourenliste wieder entsprechend gekennzeichnet.'],
    },
  },
  '3.8.9': {
    releaseDate: '06.03.2023',
    changes: {
      bugs: [
        'Die Zeitauswahl an den Tabellen ist nun wieder vollumfänglich zu nutzen. Durch Tabellen mit wenigen Zeilen kam es dazu, dass die letzten Zeilen der Zeitauswahl wie “Benutzerdefiniert” abgeschnitten waren.',
        'Die Spaltensortierung “aufsteigend/absteigend” ist nun wieder durch Klick auf die Spalte einer Tabelle zu nutzen.',
      ],
      features: [
        'Tabellenoptimierung: Kennzahl-Spalten sind nun auch filterbar. Durch Klick auf das Filter Icon innerhalb einer Kennzahl-Spalte, habt ihr nun die Möglichkeit auf gewisse Werte zu filtern: “Nur Werte größer/kleiner”.',
      ],
    },
  },
  '3.8.10': {
    releaseDate: '20.04.2023',
    changes: {
      improvements: ['Technisches Release (keine neue Funktionen für die Nutzer)'],
    },
  },
  '3.8.11': {
    releaseDate: '26.04.2023',
    changes: {
      features: [
        'Die neuen Abrechnungskennzahlen sind in LMA einsehbar. In der Kategorie „Abrechnung“ (unter „Kennzahl hinzufügen“), sind nun die folgenden Kennzahlen zu finden: Standardleistung, Zus. Mitnahme, Zeitfenster, Quittungslos, Nachname, Identservice, Gepäckabholung, Coincident, Bulky, ATG Stopps, ATG Sendungen, PS Stopps und PS-Sendungen.',
        'Die Kennzahlen sind jeweils mit (Abr) oder (Tour) gekennzeichnet. „Abr“ bedeutet Abrechnung und sind die Mengen, die im jeweiligen Zeitraum auch abgerechnet wurden. Kennzahlen mit dem Zusatz „Tour“, sind die Mengen, die im jeweiligen Zeitraum auf Tour waren.',
        'Die genauen Definitionen zu den einzelnen Kennzahlen, findest du wie gewohnt unter „Definitionen“ unten links im Menü „Informationen“.\n\n',
        'Neue Quote „Nachbarschaftsabgaben Quote“. Die Zustellungen „Nachbar“ sind nun unter “Zustellvorgänge” auch als Quote einsehbar (Nachbarschaftsabgabe Quote). Anhand der Quote ist erkenntlich, wie viele der erfolgreich zugestellten Sendungen, Nachbarschaftsabgaben waren.\n\n',
        'Konfigurierung der Scanunterbrechung in der Stoppliste. In der Stoppliste wird nun ein Uhren Symbol angezeigt, worüber die Zeit der Scanunterbrechung konfiguriert werden kann: 10, 15, 20, 25 oder 30 Minuten sind auswählbar. Über die neue Funktion kann selbst entschieden werden, wie groß die Scanunterbrechung zwischen zwei Scans sein muss, damit die Unterbrechung visualisiert wird.',
      ],
    },
  },
  '3.8.12': {
    releaseDate: '27.04.2023',
    changes: {
      improvements: [
        'Anzeige der Lademenge wird nun in SOLL-Lademenge und in IST-Lademenge dargestellt.',
        'SOLL-Lademenge sind die Sendungen, die einen Tourenausgangs-Scan und/oder einen Scan auf Tour erhalten haben. Die dazugehörige Tour muss mindestens einen Stopp ausgeführt haben, damit die Sendungen in die IST-Lademenge des Standortes einfließen. Sollten Sendungen am gleichen Tag (gleiches Tourdatum) von mehreren Touren bearbeitet werden, werden sie nur bei der Tour der letzten Bearbeitung gezählt. Damit wird eine Sendung an einem Tag immer nur einmal in die IST-Lademenge gezählt. Geht sie allerdings an mehreren Tagen auf Tour (z.B. Rücklauf beim ersten Tag), dann zählt diese Sendung auch an den jeweiligen Tagen zur IST-Lademenge.',
        'IST-Lademenge sind die Sendungen, die einen Tourenausgangs-Scan und/oder einen Scan auf Tour erhalten haben. Die dazugehörige Tour muss mindestens einen Stopp ausgeführt haben, damit die Sendungen in die IST-Lademenge des Standortes einfließen. Sollten Sendungen am gleichen Tag (gleiches Tourdatum) von mehreren Touren bearbeitet werden, werden sie nur bei der Tour der letzten Bearbeitung gezählt. Damit wird eine Sendung an einem Tag immer nur einmal in die IST-Lademenge gezählt. Geht sie allerdings an mehreren Tagen auf Tour (z.B. Rücklauf beim ersten Tag), dann zählt diese Sendung auch an den jeweiligen Tagen zur IST-Lademenge.',
        'Die SOLL-Lademenge steht als Summe bereits am Morgen zur Verfügung. Die Mengen werden zu diesem Zeitpunkt noch keiner konkreten Tour zugeordnet.',
        'Die IST-Lademenge steht als Summe zur Verfügung und ist in der Tabelle auch auf Tourebene ersichtlich.',
        'Zu den beiden Mengen stehen auch die durschnittlichen Kennzahlen zur Verfügung (ø SOLL-Lademenge und ø IST-Lademenge). Die Definition ist im Tool unter Definition und links im Menü zu finden.',
      ],
    },
  },
  '3.8.13': {
    releaseDate: '04.05.2023',
    changes: {
      features: [
        'Die Hausnummern werden in der Karte entsprechend angezeigt, sobald eine gewisse Zoomstufe erreicht ist.',
        'Es werden zwei neue SB-Gründe aufgeführt. Um klar erkennen zu können worum es bei dem Widerspruch des Kunden geht, wird nun der SB-Grund “Widerspruch” gesplittet dargestellt: 1. “Wiederspruch nach OPS Klärung”: Kunde meldet sich nach positiver Klärung durch OPS. Ein Widerspruch zu einer positiven Sendungsklärung wird durch die Kundschaft erstmalig gemeldet. 2. “Widerspruch zur Haftungsentscheidung”. Der Kunde meldet sich nach z.B. Haftungs-/Zahlungsablehnung durch den Customer Service. Ein Widerspruch z.B. zu einer Haftungs-/Zahlungsablehnung wird durch die Kundschaft erstmalig gemeldet.',
      ],
      improvements: [
        'Tabellenoptimierung: Wenn neue Kennzahlen einer Tabelle hinzugefügt werden, schließt die Auswahl nicht nach jedem Klick. Es können mehrere Kennzahlen auf einmal ausgewählt werden ohne das erneut auf das “Tabelle konfigurieren” Icon geklickt werden muss.',
      ],
      bugs: [
        'In der Ansicht des Zeitverlaufes sind benutzerdefinierte Zeiträume auswählbar.',
        'Die benutzerdefinierte Zeitauswahl der Tabellen oder Diagramme ist über den „Übernehmen“ Button wieder zu bestätigen.',
      ],
    },
  },
  '3.8.14': {
    releaseDate: '07.06.2023',
    changes: {
      improvements: [
        'Speicherung des gewählten Reiters in der Stoppliste. Wenn ein Reiter “Kennzahlen”, “Zustellart” oder “Hinweise” gewählt wird und dann das Datum oder die Tour geändert wird, speichert sich die vorgenommene Auswahl je Session. So hat man die Möglichkeit sich schneller durch die Zustellart oder die Hinweise der unterschiedlichen Touren zu navigieren.',
        'Die Spalte „Fortschritt“, steht automatisch immer dann zur Verfügung, wenn der Zeitpunkt „Heute“ in einer Tabelle ausgewählt ist. Verändert man den Zeitraum, verschwindet die Spalte.',
      ],
    },
  },
  '3.8.15': {
    releaseDate: '12.06.2023',
    changes: {
      features: [
        'Die Kennzahlen über der Stoppliste sind nun ganz individuell einzustellen. Die Kacheln können beliebig konfiguriert werden. Wenn es mehr als die jetzigen 8 Kennzahlen sein sollen, dann besteht die Möglichkeit weitere 8 Kennzahlen hinzuzufügen. Die erste Seite muss nicht maximal gefüllt sein, um die zweite Seite mit Kennzahlen zu konfigurieren.',
      ],
      improvements: [
        'Um sich die Einsätze der Touren anzuschauen, wähle bitte unter „Touren“ die Kennzahl „Einsätze“.',
      ],
    },
  },
  '3.8.16': {
    releaseDate: '13.06.2023',
    changes: {
      features: [
        'Die Uhrzeit der Tourfreigabe ist nun in LMA auf Tourbasis und als Durchschnitt einzusehen. Dies ist der Zeitpunkt, zu der ein*e Abwickler*In einem*r Zusteller*in die Freigabe erteilt hat seine*ihre heutige Tour zu starten. Die Tourfreigabe wird direkt an der Stoppliste als Uhrzeit dargestellt. Die durchschnittliche Uhrzeit, kann über “Kennzahl hinzufügen” unter der Kategorie “Touren” hinzugefügt werden (“Uhrzeit Tourfreigabe”). Die genaue Definition ist unten links im Menü unter “Definitionen” zu finden.',
      ],
    },
  },
  '3.8.17': {
    releaseDate: '21.06.2023',
    changes: {
      features: [
        'Sofern ein Adresszusatz bei den Empfängerdaten angegeben wurde, wird dieser nun auch im Adressdatensatz angezeigt.',
      ],
    },
  },
  '3.8.19': {
    releaseDate: '12.07.2023',
    changes: {
      bugs: [
        'Anpassung der Darstellung bei "keine Daten verfügbar" innerhalb der Tabellen. Die Tabellen sind nun weiterhin konfigurierbar bei nicht vorhandenen Daten.',
      ],
    },
  },
  '3.8.20': {
    releaseDate: '20.07.2023',
    changes: {
      features: [
        'Die Erreichung der eingestellten Zielwerte/Zielbereiche sind nun auch in den Tabellen farblich visualisiert. Über einen grünen/gelben oder roten Punkt innerhalb der Tabelle, wird schnell ersichtlich, ob der Zielwert/Zielbereich der Kennzahl erreicht worden ist.',
        'Erweiterung des benutzerdefinierten Zeitfilters. Die Ansicht des Zeitraumes einer Tabelle/Diagramms, ist nun auf einzelne Wochentage zu filtern. Durch Nutzung der Checkboxen, können die gewünschten Wochentage an- bzw. abgewählt werden.',
      ],
    },
  },
  '3.8.21': {
    releaseDate: '01.08.2023',
    changes: {
      features: [
        'Weitere individuelle Einstellungen der Tabellen werden über eine Session hinaus gespeichert. Dazu zählen: alle gesetzten Filter der Tabellenspalten, Auswahl der Zeit und die Auswahl der Ansicht der Einträge.',
      ],
      bugs: ['Die Brutto-Zustellzeit wird wieder im richtigen Zeitformat dargestellt.'],
    },
  },
  '3.8.22': {
    releaseDate: '16.08.2023',
    changes: {
      bugs: ['Die Filter in den Tabellen akzeptieren nun auch Kommazahlen.'],
    },
  },
  '3.8.23': {
    releaseDate: '22.08.2023',
    changes: {
      improvements: ['Technische Optimierungen (keine neue Funktionen für die Nutzer)'],
    },
  },
  '3.9.0': {
    releaseDate: '07.09.2023',
    changes: {
      features: [
        'Auf dem Dashboard im Übersichtsbereich, können nun auch bis zu 5 Reiter angelegt werden. Diese können über die das Konfigurations-Rädchen verschoben, umbenannt und gelöscht werden.',
      ],
    },
  },
  '3.9.1': {
    releaseDate: '11.09.2023',
    changes: {
      features: [
        'Die Abrechnungskennzahl “Altgerätemitnahme” ist nun als Kennzahl unter Abrechnung im Kennzahlenmenü auszuwählen und darstellbar. Die genaue Definition dazu findet man wie immer im Definitionsbereich.',
      ],
    },
  },
  '3.9.2': {
    releaseDate: '21.09.2023',
    changes: {
      improvements: [
        'Es wurden Arbeiten am Backoffice vorgenommen. Sollten Auffälligkeiten in der Berechtigungsstruktur auftreten, bitten wir über eine Kontaktaufnahme über <a href="mailto:LMA@Hermesworld.com">LMA@Hermesworld.com</a>',
      ],
    },
  },
  '3.9.3': {
    releaseDate: '09.10.2023',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.9.4': {
    releaseDate: '23.10.2023',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.9.5': {
    releaseDate: '27.10.2023',
    changes: {
      features: [
        'Stopps sind nun als KPI verfügbar. Dabei handelt es sich um drei separate Kennzahlen: <i>Tour-ATG-Stopps, Tour-HT-Stopps</i> sowie <i>Tour-PS-Stopps</i>. Im Selektor sind sie unter der Kategorie "Tourlänge & -Dauer" gelistet.',
      ],
    },
  },
  '3.9.6': {
    releaseDate: '09.11.2023',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.9.7': {
    releaseDate: '18.12.2023',
    changes: {
      features: [
        'Gefilterte Stoppart auch in der Karte zu sehen: Wird in einer Tour auf eine Stoppart geklickt, werden diese für die gesamte Tour auch in der Karte zu gefiltert und visualisiert.',
        'Abrechnungskennzahlen können nun auch anhand des numerischen Identifikators im KPI-Selektor gefunden werden.',
      ],
    },
  },
  '3.9.8': {
    releaseDate: '04.01.2024',
    changes: {
      bugs: [
        'Touren mit identischer Tournummer erscheinen nun konsistent in der Tourübersicht. Hierbei kam es zuvor zu inkonsistenten Anzeigen, je nachdem wie der Datumsfilter eingestellt wurde.',
      ],
    },
  },
  '3.9.9': {
    releaseDate: '25.01.2024',
    changes: {
      bugs: [
        'Der Excel-Export der Tabellen exportiert die Spalten nun so, dass eine Aggregation (beispielsweise Summe) möglich ist.',
      ],
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.9.10': {
    releaseDate: '21.02.2024',
    changes: {
      bugs: [
        'Die Positionsmarker innerhalb der Stoppdetails funktionieren wieder ordnungsgemäß. Vorübergehend wurden vereinzelt die GPS-Pins nicht mehr angezeigt.',
      ],
    },
  },
  '3.9.11': {
    releaseDate: '21.02.2024',
    changes: {
      bugs: [
        'Reparatur der Kennzahlensuche. Es wurden Treffer nicht angezeigt, die zu erwarten warten. So ließ sich beispielsweise die Kennzahl <i>E+0 Eil</i> mit der Eingabe <i>e+0</i> nicht finden.',
      ],
    },
  },
  '3.9.12': {
    releaseDate: '26.03.2024',
    changes: {
      features: [
        'Die Navigation besitzt nun ein App-Menü, welche Verlinkungen zu verschiedenen Hermesanwendungen enthält.',
      ],
    },
  },
  '3.9.13': {
    releaseDate: '18.04.2024',
    changes: {
      improvements: [
        'Die <i>RL-Quote</i> tauchte innerhalb des KPI-Baums in mehreren Kategorien auf. Um für mehr Klarheit zu sorgen, ist sie nun nur noch unter der Kategorie "Rückläufer" zu finden.',
      ],
    },
  },
  '3.9.14': {
    releaseDate: '06.05.2024',
    changes: {
      features: ['Der Navigation wurde eine Verlinkung zum Device Management hinzugefügt.'],
    },
  },
  '3.9.15': {
    releaseDate: '22.05.2024',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.10.0': {
    releaseDate: '04.06.2024',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.10.1': {
    releaseDate: '11.06.2024',
    changes: {
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.10.2': {
    releaseDate: '12.06.2024',
    changes: {
      bugs: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.11.0': {
    releaseDate: '27.06.2024',
    changes: {
      features: [
        'Es wurde eine neue KPI (Reihenfolgeeinhaltung) eingeführt.',
        'E+0 Standard und E+0 Eil sind nun für VP verfügbar.',
      ],
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.11.1': {
    releaseDate: '02.07.2024',
    changes: {
      features: ['RF-Einhaltung nun im Kennzahlen-Diagramm verfügbar.'],
      improvements: ['Es wurden einige technische Verbesserungen vorgenommen.'],
    },
  },
  '3.11.3': {
    releaseDate: '02.07.2024',
    changes: {
      bugs: [
        'Die Ergebnisse der Sendungssuche werden nun weiterhin angezeigt bei einem erneuten Klick in das Suchfeld.',
      ],
    },
  },
};
/* eslint-enable max-len */
export default versionData;
