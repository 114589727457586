import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import StaticValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/StaticValueExpression';

const LABEL = 'RF-Einhaltung';

const expression = new QuotientValueExpression(
  new SingleValueExpression(
    new MetricType({
      key: MetricTypeKey.ReihenfolgeeinhaltungQuote,
      aggregation: AggregationType.none,
      positiveDirection: 'up',
      nullValue: '-',
      apiVersion: 'graphql',
    })
  ),
  new StaticValueExpression(1),
  LABEL,
  true,
  null,
  null,
  getDefinitionByName(LABEL)
);

expression.setIdentifier(`${MetricTypeKey.ReihenfolgeeinhaltungQuote}_quotient`);
expression.nullValue = '-';
expression.apiVersion = 'graphql';
expression.longLabel = 'Reihenfolgeeinhaltung';

export default expression;
