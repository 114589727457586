import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import nettoZustellzeitAlt from './netto-zustellzeit-alt';
import touren from './touren';

const expression = new QuotientValueExpression(
  nettoZustellzeitAlt,
  touren,
  'Ø Netto-Zustellzeit alt',
  false,
  '-#.###.##0,0 h',
  undefined,
  getDefinitionByName('Netto-Zustellzeit alt')
);

expression.nullValue = '-';
expression.setIdentifier('nettozustellzeit/touren');

export default expression;
