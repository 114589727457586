import React, { useCallback } from 'react';
import '../assets/servicesFilterPanel.scss';
import { FilterButton } from './FilterButton';
import { servicesWithBadges, useServiceCounts } from '../hook/useListOfServicesForStop';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import { ICON_TITLES } from '../utils/ServiceAttributes';
import { getIconByName } from '../utils/ServiceIcons';
import { useTourDetailsContext } from '@other-components/tour-details-context';

type Props = {};

type ServiceFilterFn = (deliveryItem: FinishedDelivery[]) => FinishedDelivery[];

export type ServiceBadge = {
  value: string;
  serviceFn: ServiceFilterFn;
  serviceFnRet: ServiceFilterFn;
  fullServiceFn: ServiceFilterFn;
};

type ServiceIconType = {
  countBadgesToFilter: number;
  selectedServices: string[];
  handleClickServiceIcon: (serviceValue: string) => void;
  title: React.ReactChild | React.ReactChildren;
  count: number;
  remainingCount: number;
  active: boolean;
  disabled: boolean;
  value: string;
  badgesToFilter: string[];
  anomalyFilter: boolean;
};

const ServiceIcon: React.FC<ServiceIconType> = ({
  handleClickServiceIcon,
  remainingCount,
  title,
  count,
  active,
  disabled,
  value,
  badgesToFilter,
  anomalyFilter,
}) => {
  const countToDisplay = badgesToFilter?.length > 0 || anomalyFilter ? remainingCount : count;

  return (
    <FilterButton
      active={active}
      count={countToDisplay}
      disabled={disabled}
      tooltipText={title}
      onSelect={() => handleClickServiceIcon(value)}
      customIcon={getIconByName(value)}
    />
  );
};

const ServicesFilterPanel: React.FC<Props> = () => {
  const {
    finishedDeliveries: stopList,
    filteredFinishedDeliveries: filteredFDList,
    serviceFilterState: [selectedServices, setSelectedServices],
    zustellartFilterState: [badgesToFilter],
    showOnlyWithAnomalies,
  } = useTourDetailsContext();

  const handleClickServiceIcon = useCallback(
    (selectedService: string) => {
      setSelectedServices((state) => {
        const isActivated = state.includes(selectedService);
        if (isActivated) {
          return state.filter((v) => v !== selectedService);
        } else {
          return [selectedService, ...state];
        }
      });
    },
    [setSelectedServices]
  );

  const { counts: initialCounts } = useServiceCounts(stopList);
  const { counts: filteredListCounts } = useServiceCounts(filteredFDList);

  const serviceIcons = Object.values(servicesWithBadges).map((service: ServiceBadge) => {
    const { stops: initialCount, shipments } = initialCounts[service.value];
    const { stops: stopsOfFilteredList } = filteredListCounts[service.value];

    const title = (
      <>
        {ICON_TITLES[service.value]}
        <br />
        Stopps: {initialCount}
        <br />
        Sendungen: {shipments}
      </>
    );

    if (!initialCount) {
      return null;
    }

    const disabled = (() => {
      // An active service should always be deselectable
      if (selectedServices.includes(service.value)) return false;

      if (badgesToFilter.length > 0 || showOnlyWithAnomalies) {
        return stopsOfFilteredList === 0;
      } else {
        return initialCount === 0;
      }
    })();

    return (
      <ServiceIcon
        key={service.value}
        active={selectedServices.some((o) => o === service.value)}
        value={service.value}
        disabled={disabled}
        count={initialCount}
        badgesToFilter={badgesToFilter}
        remainingCount={stopsOfFilteredList}
        title={title}
        selectedServices={selectedServices}
        countBadgesToFilter={1}
        handleClickServiceIcon={handleClickServiceIcon}
        anomalyFilter={showOnlyWithAnomalies}
      />
    );
  });

  return <>{serviceIcons}</>;
};

export default ServicesFilterPanel;
