import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';

export const formatValueExpressionValue = (
  valueExpression: ValueExpression,
  veData?: number | null | string,
  // this value will be used if there is no value and nullValue is not specified on valueExpression
  fallbackValue: number | null = 0
) => {
  if (!valueExpression) {
    throw new Error('No value expression given');
  }

  const formatter = valueExpression.getValueFormatter();

  if (veData === null || veData === undefined || (typeof veData === 'number' && isNaN(veData))) {
    if (valueExpression.nullValue !== undefined) {
      return valueExpression.nullValue;
    } else {
      return fallbackValue;
    }
  }
  return formatter(veData);
};
