import React, { FC, ReactElement } from 'react';
import style from './tabs.module.scss';
import classNames from 'classnames';

type Props = {
  borderBottom?: boolean;
};

const Tabs: FC<Props> = ({ borderBottom = false, children }): ReactElement => {
  return (
    <div
      className={classNames(style.tabs, {
        [style.borderBottom]: borderBottom,
      })}>
      {children}
    </div>
  );
};
export default Tabs;
