import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.SatDeliveryitemsIntakeForecastCreatedAt,
  label: 'Prognostizierte Sendungseingangsmenge', // TODO: it has same label prognose-sendungseingangsmenge.ts Check it.
  aggregation: AggregationType.max,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  category: 'sat',
  description: '<p>Erstellungsdatum der Prognose für die Sendungseingangsmenge</p>\n',
  nullValue: '-',
  hidden: true,
});

const expression = new SingleValueExpression(metric);

export default expression;
