import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import zustellungenArt from './zustellungen-art';
import zustellungen from './zustellungen';
import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';

const nachbarzustellungen = new MapDetailValueExpression(zustellungenArt.metricType, 'person:nextDoor');

const expression = new QuotientValueExpression(
  nachbarzustellungen,
  zustellungen,
  'Nachbarschaftsabgabe Quote',
  true,
  null,
  null,
  getDefinitionByName('Nachbarschaftsabgabe Quote')
);

expression.nullValue = '-';
expression.setIdentifier('zustellungen_nachbar_quote');

export default expression;
