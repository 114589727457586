import QuotientValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/QuotientValueExpression';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import liegenbleiber from './liegenbleiber';
import bestand from './bestand';

const expression = new QuotientValueExpression(
  liegenbleiber,
  bestand,
  'Liegenbleiber-Quote',
  true,
  undefined,
  undefined,
  getDefinitionByName('Liegenbleiber-Quote')
);

expression.nullValue = '-';
expression.setIdentifier('stock.remaining_per_inventory');

export default expression;
