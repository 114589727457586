import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import { MetricTypeKey } from './metric-type-keys';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const metric = new MetricType({
  key: MetricTypeKey.ErpCostsToursAmountByType,
  label: 'Summe der Tourvergütung nach Abrechnungsart',
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  valueFormat: '#.###.##0,0€',
  category: 'costs',
  description: '<p>Summe der abgerechneten Beträge für Tourvergütung nach Abrechnungsart</p>\n',
  nullValue: '-',
});

export default new SingleValueExpression(metric);
